import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faShoppingCart, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import translate from '../../languages'

export default class LoadingNav extends Component {
  render() {
    return (
      <nav className='MobileNavbar RowCenter'>
        <div className='Sidebar'>
          <div className='SidebarIcon'>
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>

        <AddressSkeleton />


        <div className='Carrito'>
          <button className='svg-disabled'>
            <FontAwesomeIcon icon={faShoppingCart} />
          </button>
        </div>
      </nav>
    )
  }
}


const AddressSkeleton = () => (
  <div className='UserAddress'>
    <button className={'Btn RowCenter BtnAddress'}>
      <p>
        {translate.t('userAddres')}
      </p>
      <FontAwesomeIcon icon={faAngleDown} />
    </button>
  </div>
)

export { AddressSkeleton }