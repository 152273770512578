import i18n from "i18next";

// Catalogo
import argentina from "./countrys/argentina.json";
import mexico from "./countrys/mexico.json"

// Detectar Idioma
const country = process.env.REACT_APP_MP_COUNTRY;
var lng = "ar";
if (country === 'MLA') lng = "ar";
if (country === 'MLM') lng = "mx";

// Translation catalog
const resources = {
    ar: { translation: argentina },
    mx: { translation: mexico },
};

// Initialize i18next with catalog and language to use
i18n
    .init({
        resources,
        lng: lng
    });

export default i18n;