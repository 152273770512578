import React, { Component } from 'react'

export default class TheyTrustUs extends Component {
  render() {
    return (
      <section className='TheyTrustUs'>
        <h4>Ya confían en nosotros</h4>
        <ul>
          <li>
            <a rel="noopener noreferrer" href="https://www.forbesargentina.com/innovacion/el-doble-boom-mispichos-forbeslive-ecommerce-mascotas-n3111" target="_blank">
              <img src="https://oliver.pet/assets/forbes.png" alt="" />
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://www.endeavor.org.ar/mispichos-renovaron-la-industria-los-pet-shops-hoy-los-lideres-del-mercado/" target="_blank">
              <img src="https://oliver.pet/assets/endeavor.png" alt="" />
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://www.santander.com.ar/banco/online/pymes-advance/formacion-empresarial/pildoras-de-conocimiento/pymes-que-inspiran/mis-pichos-los-amigos-digitales-de-tu-mascota" target="_blank">
              <img src="https://oliver.pet/assets/santander.png" alt="" />
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://latam.googleblog.com/2020/06/estas-son-las-12-startups-del-google-startups-accelerator-latam-2020.html" target="_blank">
              <img src="https://oliver.pet/assets/google.png" alt="" />
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="#" target="_blank">
              <img src="https://oliver.pet/assets/facebook.png" alt="" />
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://www.lanacion.com.ar/economia/sin-titulo-nid2322525/" target="_blank">
              <img src="https://oliver.pet/assets/lanacion.png" alt="" />
            </a>
          </li>
        </ul>
      </section>
    )
  }
}
