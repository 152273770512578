import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumberMP from './FormatNumber';
import { isMobile } from 'react-device-detect';
import { MyImage } from './Inputs';

class ProductBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: this.props.dataProducto,
      variations: this.props.dataProducto.array_variaciones,
      variedad_select: [],
      photo: '',
    }
  }

  componentDidMount() {
    this.dimensions();
    this.lastedElements();
  }

  componentWillReceiveProps(next_props) {

    // Nuevo producto
    if (next_props.dataProducto !== this.props.dataProducto) {
      this.setState({
        producto: next_props.dataProducto,
        variations: next_props.dataProducto.array_variaciones,
        variedad_select: []
      }, () => {
        // 
        window.scrollTo(0, 0);
        this.dimensions();
        this.lastedElements();
      });
    }
  }


  dimensions = () => {
    var Mobile = '', replaced = '';
    if (isMobile && this.state.variedad_select.linkfoto !== undefined) {
      Mobile = this.state.variedad_select.linkfoto
      replaced = Mobile.replace("desktop_", "mobile_")
      this.setState({ photo: replaced }, () => this.discount())
    } else {
      Mobile = this.state.variedad_select.linkfoto
      this.setState({ photo: Mobile }, () => this.discount())
    }
  }

  lastedElements() {
    var Aux = this.state.variations
    if (this.state.variations !== undefined) {
      var last_element = Aux[Aux.length - 1];
      this.setState({ variedad_select: last_element }, () => this.dimensions())
    }
  }

  selectVariation(variacion) {
    this.setState({ variedad_select: variacion }, () => this.dimensions(), this.discount())
  }

  transformStringToUriString = (value) => {
    let newValue = value
    newValue = newValue.replace(/ /g, "-")
    newValue = newValue.replace(/\//g, "")
    return newValue;
  }

  createLinkToProduct = () => {
    let url = this.getLinkToProduct();
    return this.props.history.push(url.toLowerCase());
  }

  getLinkToProduct = () => {
    let catName = this.props.dataProducto.cat_nombre ? this.transformStringToUriString(this.props.dataProducto.cat_nombre) : null;
    let brandName = this.props.dataProducto.mar_nombre ? this.transformStringToUriString(this.props.dataProducto.mar_nombre) : null;
    let prodName = this.props.dataProducto.pro_nombre ? this.transformStringToUriString(this.props.dataProducto.pro_nombre) : null;
    let proId = (this.props.dataProducto.pro_id);
    let prv_id = (this.state.variedad_select.prv_id)
    let url = '/productos/' + catName + '/' + brandName + '/' + prodName + '/' + proId + '/' + prv_id
    return url;
  }

  discount = () => {
    if (this.state.variations !== undefined) {
      var dif = 0
      var calc = 0
      var result = 0
      this.state.variations.map((variacion, i) => {
        if (variacion.prv_id === this.state.variedad_select.prv_id) {
          dif = variacion.prv_precio_lista - variacion.prv_precio_real
          calc = dif / variacion.prv_precio_lista
          result = calc * 100
        }
        this.setState({ result: result > 1 ? Math.round(result) : 0 })

        return result
      })
    }
  }


  render() {
    var descontinuado = false
    if (this.state.variations === undefined) {
      descontinuado = true
      //console.log("FOTO DEL PROD", this.state.photo)
    }
    //console.log("descuentos??", this.props.dataProducto)
    return (
      <li key={this.props.keyValue} className={descontinuado ? 'ProductBlock ProductDisabled' : 'ProductBlock'}>
        {!descontinuado && this.state.result > 1 ? <p className='ProductPill'>{this.state.result}% Off </p> : null}
        <div className="RowCenter SpaceCenter">
          <div />
          <Link style={{ color: 'initial' }} to={this.getLinkToProduct()}>
            <MyImage alt={this.state.producto.pro_foto} image={{ src: this.state.photo ? this.state.photo : this.state.producto.linkfoto, class: 'ProductBlockImg' }} />
          </Link>
          <div />
        </div>
        {/*this.props.dataProducto.pro_sticker_cantidad !== 0 ?

          <span className='ProductPill'>
            {this.props.dataProducto.pro_sticker_cantidad}
          </span>
          : null
        */}
        <div>
          <Link style={{ color: 'initial' }} to={this.getLinkToProduct()}>
            <h4>
              {this.props.dataProducto.mar_nombre}
            </h4>
            <small>
              {this.state.producto.pro_nombre}
            </small>
          </Link>
          <ul>
            {this.state.variations !== undefined ?
              this.state.variations.map((variacion, o) =>
                <li
                  key={o}
                  onClick={() => this.selectVariation(variacion)}
                  className={this.state.variedad_select === variacion ? 'active' : ''} //State de variation selecctedd para definir active : '' 
                >
                  {variacion.var_cantidad + " " + variacion.ume_resumido}
                </li>
              ) :
              <li className={''}>
                Descontinuado
              </li>
            }
          </ul>
          <b className='RowCenter'>
            {this.state.variations !== undefined &&
              this.state.variations.map((variacion, i) =>
                variacion.prv_id === this.state.variedad_select.prv_id && variacion.prv_precio_lista !== 0 ?
                  <p key={i} className='oldPrice'>
                    <NumberMP total={variacion.prv_precio_lista} prefix={'$'} />
                  </p>
                  :
                  null

              )}
            {this.state.variations !== undefined &&
              this.state.variations.map((variacion, i) =>
                variacion.prv_id === this.state.variedad_select.prv_id &&
                <p key={i} className='newPrice'>
                  <NumberMP total={variacion.prv_precio_real} prefix={'$'} />
                </p>
              )}
          </b>

          {!descontinuado &&
            <Link style={{ color: 'initial' }} to={this.getLinkToProduct()}>
              <span className='RowCenter MotitoBox'>
                <img className='Motito' alt='motito verde' src={require('../../IMG/icono-motito.png')} />
                <p>Llega gratis {this.props.dia_gratis}</p>
              </span>
            </Link>
          }
          {!descontinuado &&
            <Link to={this.getLinkToProduct()}>
              <button className='btn-CompraVerde'>
                Comprar
              </button>
            </Link>
          }
        </div>
      </li>
    );
  }
}

export default withRouter(ProductBlock)