import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

export default class ButtonMasomenos extends React.Component {


  render() {
    return (
      <div className='Btn-MasoMenos'>
        <button onClick={() => this.props.removeCount()} >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <p>
          {this.props.cant}
        </p>
        <button onClick={() => this.props.addPord()} >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    );
  }
}

const ButtonLoading = ({ loading, title, onClick, varStyle }) => (

  <button className={varStyle ? varStyle : 'w100 Btn-Action ColumnCenter JustifyCenter'} onClick={() => !loading && onClick()} >
    {!loading ?
      <div>
        {title}
      </div>
      :
      <div className='btn-ActiveLoading'>
        <FontAwesomeIcon icon={faSpinner} />
      </div>
    }
  </button>

)

export { ButtonLoading };