import React, { Suspense } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router-dom";
import FacebookLoginButton from '../Login/FacebookLoginButton';
import GoogleLoginButton from '../Login/GoogleLoginButton';
import { AlertCompound } from './Alert';
import translate from '../../languages';
import { FlagArg, FlagCl, FlagMx } from './OliverIcons';
import { OLIVER_PETS } from '../../utils/Oliverhelper';
//import LitteOrder from './LittleOrder';

const LoginCard = React.lazy(() => import('../Login/LoginCard'));
class Modal extends React.Component {

  hideModal = () => { this.props.closeModal() }

  dataOrder = () => {
    //console.log("response de la data", this.props.data)
    var orderId = this.props.data.compra;
    return this.props.history.push({ pathname: '/compra-finalizada', state: { detail: orderId } });
  }

  modalGeneric = () => {
    return (
      <section className={'CustomModal Open'}>
        <article className='ModalBody'>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h4>omar funciona!</h4>
        </article>
      </section>
    )
  }

  modalCountrys = () => {
    document.title = 'Seleccionar pais' + OLIVER_PETS
    return (
      <section className={'CustomModal Open'}>
        <article className='ModalBody'>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h4>Selecciona tu ubicacion</h4>
          <ol className='CountrysOption'>
            <li onClick={() => window.location.href = 'https://mx.oliver.pet'}>
              <FlagMx />
              <span>México</span>
            </li>
            <li onClick={() => window.location.href = 'https://mispichos.com'}>
              <FlagArg />
              <span>Argentina</span>
            </li>
            <li className='Disabled'>
              <FlagCl />
              <span>Chile</span>
            </li>
          </ol>
        </article>
      </section>
    )
  }


  modalNoPhoto = () => {
    return (
      <section className={'CustomModal Open'}>
        <AlertCompound
          labelText={'Vas a guardar tu mascota sin foto'}
          alternativeLabel={'Omitir'}
          actionLabel={'Elegir foto'}
          onPress={this.props.closeModal}
          onPressAlternative={this.props.saveAnimal}
        />
      </section>
    )
  }


  modalPromosBancarias = () => {
    var promos = this.props.data
    //console.log("Pormociones bancarias", promos)
    return (
      <section className={'CustomModal Open'}>
        <article className='ModalBody'>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h4>Promociones Bancarias</h4>
          <ol className='PromoBancariaItem'>
            {promos.map((each_prom, index) => (
              <li key={index}>
                <div className='RowCenter SpaceBetween AlignCenter'>
                  <h5>
                    {each_prom.issuer.name}
                  </h5>
                  {each_prom.picture !== null ?
                    <img alt='tarjetas' src={each_prom.picture.url} />
                    :
                    null}
                </div>
                <div>
                  {each_prom.recommended_message}
                </div>
                <div>
                  {each_prom.payment_methods.map((eachMethod, i_method) => (
                    <div key={i_method} >
                      {eachMethod.name}
                    </div>
                  ))}
                </div>
              </li>)
            )}
          </ol>
        </article>
      </section>
    )
  }



  modalSuccesPay = () => {
    return (
      <section className={'CustomModal Open'}>
        <article className='ModalBody'>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h4>Pago exitoso!</h4>
          <FontAwesomeIcon icon={faTimes} />
          <ol>
            {/*<LitteOrder sinSombra={true}/>*/}
            <li>
              Aca viene el miniOrder
            </li>
          </ol>
          <div className='RowCenter'>
            <div />
            <button onClick={() => this.dataOrder()} className={'Btn-link'}>
              Continuar
            </button>
          </div>
        </article>
      </section>
    )
  }

  modalDeclinedPay = () => {
    //console.log(this.props.retryPayment)
    return (
      <section className={'CustomModal Open'}>
        <article className='ModalBody Bg-Rojo p1'>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h4 className='TextWhite'>Pago rechazado!</h4>

          {/*<p>{this.props.data.response.message}</p>*/}

          <button className='w100 Btn Btn-OutlineWhite mt-1' onClick={() => this.props.retryPayment('retry')}>Intentar con la Misma Tarjeta</button>

          <button className='w100 Btn Btn-OutlineWhite mt-1' onClick={() => this.props.retryPayment('newCard')}>Probar con otra tarjeta</button>

          <button className='w100 Btn Btn-OutlineWhite mt-1' onClick={() => this.props.retryPayment('efectivo')}> {translate.t('cashAtHome')}</button>

          <div className={'Btn  mt-1'} onClick={() => { this.hideModal() }}>
            Volver
          </div>

        </article>
      </section>
    )
  }


  modalLogin = () => {
    return (
      <section className={'CustomModal Open'} >
        <div className={'ModalBody'}>
          <button className='BtnCloseModal' onClick={() => { this.hideModal() }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <article className='SingUpBox ConAire'>
            <h3 className='w100'>Continua con</h3>
            <div className="LoginButtonOptions">
              <FacebookLoginButton />
              <GoogleLoginButton />
              <div className='Separador'>
                <span>ó</span>
              </div>
            </div>
            <Suspense fallback={<div />}>
              <LoginCard showForm={true} loginForm={true} />
            </Suspense>
          </article>
        </div>
      </section>
    )
  }

  showModalType = () => {

    if (this.props.type === 'menu') {
      return this.modalMenu();
    }
    else if (this.props.type === 'PromosBancarias') {
      return this.modalPromosBancarias();
    }
    else if (this.props.type === 'loginButton') {
      return this.modalLogin();
    }
    else if (this.props.type === 'SuccesPay') {
      return this.modalSuccesPay();
    }
    else if (this.props.type === 'DeclinedPay') {
      return this.modalDeclinedPay();
    }
    else if (this.props.type === 'NoPhoto') {
      return this.modalNoPhoto();
    }
    else if (this.props.type === 'Countrys') {
      return this.modalCountrys();
    }
    return this.modalGeneric();
  }

  render() {
    return this.props.showModal && this.showModalType()
  }
}

export default withRouter(Modal)

