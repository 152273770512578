import React from 'react';
import Skeleton from './Skeleton';


const LoadComponentSliderMiniProduct = () => {
    var auxLoad = [{}, {}, {}, {}]
    return auxLoad.map((elem, key) =>
        <div key={key}>
            <LoadComponentMiniProduct />
        </div>
    )
}

const LoadComponentMiniProduct = () => (
    <div className='LittleProducBlock'>
        <Skeleton width={'100%'} height={'120px'} />
        <div>
            <h4 style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }}>
                <Skeleton width={'95%'} height={'30px'} />
            </h4>
            <ul style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }} className='Variation'>
                <Skeleton width={'95%'} height={'30px'} />
            </ul>
            <div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }} className='w100 RowCenter SpaceBetween '>
                <Skeleton width={'91%'} height={'30px'} />
            </div>
        </div>
    </div>
)

const LoadComponenFavProduct = () => (
    <div className='LittleProducBlock'>
        <Skeleton width={'100%'} height={'135px'} />
        <div>
            <h4 style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }}>
                <Skeleton width={'95%'} height={'30px'} />
            </h4>
            <ul style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }} className='Variation'>
                <Skeleton width={'95%'} height={'30px'} />
            </ul>
            <div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "10px" }} className='w100 RowCenter SpaceBetween '>
                <Skeleton width={'91%'} height={'30px'} />
            </div>
        </div>
    </div>
)


const LoadComponentOliverIcons = () => {
    return (
        //: 'BrandProfile
        <div>
            <div style={{ marginTop: "10%" }} className='BrandHeader RowCenter'>
                <Skeleton width={'1000%'} height={'30px'} />
            </div>
            <dl>
                {/*marcas*/}
                <li style={{ margin: "10px" }}>
                    <div className='RowCenter'>
                        <Skeleton width={'70px'} height={'70px'} rounded={true} />
                        <Skeleton width={'70px'} height={'70px'} rounded={true} />
                        <Skeleton width={'70px'} height={'70px'} rounded={true} />
                        <Skeleton width={'70px'} height={'70px'} rounded={true} />
                    </div>
                </li>
            </dl>
        </div>
    )
}

const LoadComponentBigProduct = () => {
    return (
        <li className={'ProductBlock'}>
            {/*img */}
            <p style={{ marginLeft: '30%', marginTop: 0 }}> <Skeleton width={'60%'} height={'200px'} /></p>
            <small>
                {/*name*/}
                <Skeleton width={'100%'} height={'35px'} />
            </small>
            <b className='RowCenter'>
                {/*variacion*/}
                <li><Skeleton width={'800%'} height={'40px'} /></li>
                <li style={{ marginLeft: '35%' }}><Skeleton width={'800%'} height={'40px'} /></li>
                <li style={{ marginLeft: '35%' }}><Skeleton width={'800%'} height={'40px'} /></li>
            </b>
            <p style={{ margin: '10px' }}> <Skeleton width={'100%'} height={'35px'} /></p>
        </li >
    )
}


const LoadComponentMiniIndividualProduct = () => (
    <section className={'IndividualProduct'}>
        <div className="ColumnCenter">
            <Skeleton width={'70%'} height={'230px'} />
            {/**Imagen del Producto */}

            <br />
            <div className={'VisibleOnlyDesktop w80'}>
                <Skeleton width={'100%'} height={'60px'} />
            </div>
            <br />

        </div>

        <div className="ColB">
            <Skeleton width={'100%'} height={'30px'} /><br />
            <Skeleton width={'30%'} height={'30px'} /><br />

            <div className='IndProdVariations pb-1'>
                <div className={'RowCenter'}>
                    <Skeleton width={'45%'} height={'40px'} />
                    <Skeleton width={'45%'} height={'40px'} />
                </div><br></br>
                <div className={'RowCenter'}>
                    <Skeleton width={'45%'} height={'40px'} />
                    <Skeleton width={'45%'} height={'40px'} />
                </div><br></br>

            </div>
            <div className={'RowCenter VisibleOnlyDesktop'}>
                <div />
                <Skeleton width={'50%'} height={'40px'} />
            </div>
            <div className={'RowCenter VisibleOnlyMobile'}>
                <Skeleton width={'100%'} height={'40px'} />
            </div>
        </div>
        <div className='Aire-200 VisibleOnlyMobile' />
    </section >
)


const LoadComponentBigIndividualProduct = () => (
    <section className={'IndividualProduct'}>

        <div className="ColA">
            <div className={'IndProdHead'}>
                <Skeleton width={'60%'} height={'320px'} />

                {/**Imagen del Producto */}
            </div>

        </div>
        <div style={{ margin: '10px' }}>
            <Skeleton width={'100%'} height={'40px'} />
        </div>
        <div style={{ margin: '10px' }}>
            <Skeleton width={'100%'} height={'40px'} />
        </div>


        <div className="ColB">
            <div>
                <div className='DeliveryHead pb-1 VisibleOnlyDesktop'>
                    <Skeleton width={'100%'} height={'40px'} />
                </div>
                <div className='RowCenter SpaceBetween pb-1'>
                    <div className='Rate RowCenter'>
                        <div className='RowCenter'>
                            <Skeleton width={'100%'} height={'40px'} />
                        </div>
                    </div>
                    <div className='Delivery RowCenter'>
                        <Skeleton width={'100%'} height={'40px'} />
                    </div>
                </div>
            </div>
        </div>
    </section>
)


const LoadingComponentPetPorfile = () => {
    var aux = [{}, {}, {}]
    return (
        <section className='PetProfile SlideBox'>
            <article className='ShowElement PrimaryBox'>
                <div className='PetProfileBlock RowCenter'>
                    <Skeleton width={'50px'} height={'50px'} rounded={true} />
                    <div className='w100'>
                        <h5><Skeleton width={'150px'} height={'30px'} /></h5>
                    </div>
                </div>
                <ol>
                    {aux.map((elem, i) =>
                        <li key={i} className='Tile'>
                            <Skeleton width={'80px'} height={'80px'} />
                        </li>
                    )}
                </ol>
            </article>
        </section>

    )
}


const LoadingComponentHomeMobile = () => (
    <section className='MobileCanvas'>
        <ul className='MobileList'>
            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /> </li>
            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /></li>
        </ul>

        <ul className='MobileList'>
            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /> </li>

            {/**Aca la foto de la mascota */}
            <LoadingPetCarrousel />

            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /></li>
        </ul>

        <ul className='MobileList'>
            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /></li>
            <li className='MobileListItem'><Skeleton width={'100px'} height={'100px'} rounded={true} /></li>
        </ul>

    </section>

)

const LoadingPetCarrousel = ({ big }) => (
    <section className={big ? 'PetCarrusel' : 'MobilePetCarrusel'}>
        <div>
            <div />
            <ol className='PetList' >
                <li className={'PetBubble ShowElement'}><Skeleton width={'100px'} height={'100px'} rounded={true} /></li>
            </ol>
            <div />
        </div>
    </section>
)

const LoadingHomeBanner = () => (
    <section className='ColumnCenter MobileLogoBox'>
        <Skeleton width={'350px'} height={'58px'} />
    </section>
)

const LoadingPromotion = () => (
    <section className='ColumnCenter MobileLogoBox'>
        <Skeleton width={'340px'} height={'110px'} />
    </section>
)


export {
    LoadComponentSliderMiniProduct,
    LoadComponentMiniProduct,
    LoadComponentOliverIcons,
    LoadComponentBigProduct,
    LoadComponentMiniIndividualProduct,
    LoadComponentBigIndividualProduct,
    LoadComponenFavProduct,
    LoadingComponentPetPorfile,
    LoadingComponentHomeMobile,
    LoadingHomeBanner,
    LoadingPromotion
}