import React, { Component } from 'react'
import Modal from './Modal';
import { FlagArg, FlagCl, FlagMx } from './OliverIcons';

export default class SelectCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pais: process.env.REACT_APP_MP_COUNTRY === 'MLM' ? 'mx' : (
        process.env.REACT_APP_MP_COUNTRY === 'MLA' ? 'ar' : ''),
      showModal: false,
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }
  render() {
    let pais = this.state.pais;
    let flagComponent = pais === 'mx' ? <FlagMx /> : (pais === 'ar' ? <FlagArg /> : <FlagCl />);
    let countryName = pais === 'mx' ? `México` : (pais === 'ar' ? `Argentina` : `Chile`);

    return (
      <div>
        <div
          onClick={() => this.setState({ showModal: true })}
          className='Btn-SelectCountry'>
          {flagComponent}<span>{countryName}</span>
        </div>
        <Modal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          type={'Countrys'}
        />
      </div>
    )
  }
}
