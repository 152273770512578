import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogo } from '../Tools/OliverIcons';
import OliverHelper from '../../utils/Oliverhelper';
import AuthService from '../../utils/AuthService';
import { withRouter } from 'react-router-dom';

const Auth = new AuthService();

class GoogleLoginButton extends React.Component {

    responseGoogle = (response) => {
        console.log("RESPONSE GOOGLE", response);
        if (response.profileObj) {
            // User
            let user = {
                uid: response.profileObj.googleId,
                email: response.profileObj.email,
                displayName: response.profileObj.name,
                photoURL: response.profileObj.imageUrl,
                firstName: response.profileObj.givenName,
                lastName: response.profileObj.familyName,
                auth: {
                    idTokenExpirationDate: response.tokenObj.expires_in,
                    accessToken: response.tokenObj.access_token,
                    idToken: response.tokenObj.id_token
                }
            }
            // Login
            let params = {
                googleAuth: 1,
                data: user
            }

            this.login(params);
        }
    }

    login = (params) => {
        //Promise
        const promise = new Promise(async (resolve, reject) => {
            try {

                OliverHelper.ClientAPI.login(params).then((response) => {
                    //console.log('response login', response);
                    if (response && response.payload) {
                        if (!response.payload.status) {
                            alert(response.payload.message);
                        } else {
                            Auth.setTokenUser(response.payload.jwt);
                            resolve({ status: 1, data: { jwt: response.payload.jwt } })
                            //this.props.history.push('/');
                            this.reloadPage();
                        }
                    } else {
                        resolve({ status: 0, data: 'tuvimos problemas para conectarnos con el servidor' })
                    }

                }).catch(error => {
                    //console.log('error en login', error);
                    resolve({ status: 0, data: 'error en login' })
                });
            }
            catch (err) {
                console.warn('Error: ', err)
                reject('Error: ' + err)
            }
        })

        // return
        return promise
    }

    reloadPage = () => {

        if (window.location.pathname !== '/registro' && window.location.pathname !== '/registro/login') {
            window.location.reload();
        } else {
            this.props.history.push('/loading')
            return this.props.history.go();
        }
    }

    render() {
        return (
            <GoogleLogin
                clientId={'739198489978-ve7f2j49nhnl2e7a59k9rlbqt7eh5bhb.apps.googleusercontent.com'}
                render={renderProps => (
                    <button className="RowCenter"
                        onClick={() => {
                            this.props.activeLoading && this.props.activeLoading();
                            renderProps.onClick();
                        }}>
                        <GoogleLogo />
                        <span >Continuar con Google</span>
                    </button>
                )}
                buttonText="Login"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
        );
    }
}

export default withRouter(GoogleLoginButton)