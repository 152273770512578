import React from 'react';
import { isMobile } from 'react-device-detect';
import MobileNavbar from './MobileNavbar';
import HomeNavbar from './HomeNavbar';
import BajateLaApp from '../Layout/BajateLaApp';
import ArriveSafely from '../Tools/ArriveSafely';

export default class SuperNav extends React.Component {
  render() {
    return (
      <header className='fixedNavbar'>
        {isMobile && window.location.pathname === '/' && <BajateLaApp />}

        {isMobile ?
          <MobileNavbar
            case={this.props.case}
            toggleDrawer={this.props.toggleDrawer}
            showDrawer={this.props.showDrawer}
            showAddress={this.props.showAddress}
            showSide={this.props.showSide}
            searchFocus={this.props.searchFocus}
            showCart={this.props.showCart}
          />
          :
          <HomeNavbar
            case={this.props.case}
            toggleDrawer={this.props.toggleDrawer}
            showDrawer={this.props.showDrawer}
            showAddress={this.props.showAddress}
            showSide={this.props.showSide}
            showCart={this.props.showCart}
            toggleModal={this.props.toggleModal}
            showLoginForm={this.props.showLoginForm}
          />
        }

        {false && <ArriveSafely />}
      </header>
    )
  }
}
