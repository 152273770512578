import React from 'react';
import { withRouter } from "react-router-dom";
import { MyImage } from './Inputs';

class BrandBubble extends React.Component {
  //  {console.log('marca >>> ', this.props.dataBrand)}


  getLinkPath = () => {
    return `/productos?marca=` + this.props.dataBrand.mar_name.replace(/ /g, "-").replace("'", "-");
  }

  redirectProd = (e) => {
    e.preventDefault();
    console.log(this.props.dataBrand.mar_name)
    this.props.history.push({
      pathname: "/productos",
      search: '?marca=' + this.props.dataBrand.mar_name.replace(/ /g, "-").replace("'", "-"),
      state: ' de ' + this.props.dataBrand.mar_name
    })
  }

  render() {
    //console.log("marca", this.props.dataBrand.mar_name.replace(/ /g, "-").replace("'", "-"))
    return (
      <a href={this.getLinkPath()} onClick={e => this.redirectProd(e)} className='BrandBubble'>
        <MyImage alt={this.props.dataBrand.mar_name} image={{ src: this.props.dataBrand.mar_logo }} />
        {Math.round(this.props.dataBrand.mar_discount) > 4 &&
          <span className='BrandBubbleTag' role="img" aria-labelledby="fueguito">🔥 Hasta {Math.round(this.props.dataBrand.mar_discount)}% OFF</span>
        }
      </a>
    );
  }
}


export default withRouter(BrandBubble)