import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Alert extends React.Component {

  onPress = () => { this.props.onPress ? this.props.onPress() : console.log("set") }

  render() {
    return (
      <article
        onClick={() => this.onPress()}
        className={'Alert Alert-' + (this.props.type ? this.props.type : 'Normal')}
      >
        <div className='RowCenter'>
          <h5>
            {this.props.labelText}
          </h5>
        </div>
        {this.props.secondLabel &&
          <p>
            {this.props.secondLabel}
          </p>}
      </article>
    )
  }
}

class AlertSimple extends React.Component {

  onPress = () => { this.props.onPress ? this.props.onPress() : console.log("set") }

  render() {
    return (
      <article
        className={'Alert Alert-' + (this.props.type ? this.props.type : 'Normal')}
      >
        <h5>
          {this.props.labelText}
        </h5>

        {this.props.secondLabel &&
          <p>
            {this.props.secondLabel}
          </p>}

        {this.props.actionLabel &&
          <div className='RowCenter SpaceBetween'>
            <div />
            <button onClick={() => { this.onPress() }}>
              {this.props.actionLabel}
            </button>
          </div>
        }
      </article>
    )
  }
}

class AlertCompound extends React.Component {

  onPress = () => { this.props.onPress ? this.props.onPress() : console.log("set") }
  onPressAlternative = () => { this.props.onPressAlternative() }

  render() {
    return (
      <article
        className={'Alert Alert-' + (this.props.type ? this.props.type : 'Normal')}
      >
        {!this.props.loading ?
          <div>
            <h5>
              {this.props.labelText}
            </h5>
            {this.props.secondLabel &&
              <p>{this.props.secondLabel}</p>
            }
            <div className='RowCenter SpaceBetween'>
              <p className='Alternative' onClick={() => { this.onPressAlternative() }}>
                {this.props.alternativeLabel ? this.props.alternativeLabel : 'Sin definir'}
              </p>
              <button onClick={() => { this.onPress() }}>
                {this.props.actionLabel ? this.props.actionLabel : 'Accionable'}
              </button>
            </div>
          </div>
          :
          <div className='btn-ActiveLoading'>
            <FontAwesomeIcon icon={faSpinner} />
          </div>
        }
      </article>
    )
  }
}


export { Alert, AlertSimple, AlertCompound }