import CartOliver from './CartOliver';
import AuthService from './AuthService';

const Auth = new AuthService();

const ITEMS_TO_SHOW_PROD_HOME = 6;
const DOMICILIO_NAME = 'mispichos_domicilio';
const OLIVER_PETS = ' - Oliver Pets';
const urlRaiz = 'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/wo_elementos/';
const OliverHelper = {
  hidePopupApp: function () {
    localStorage.setItem('dowloadApp', true);
    if (document.getElementsByClassName('BajateLaApp').length) {
      document.getElementsByClassName('BajateLaApp')[0].style.display = 'none';
      //document.getElementsByClassName('MobileLogoBox')[0].style.margin = 'auto';
      if (document.getElementById("LOGINBOX")) {
        document.getElementById("LOGINBOX").classList.remove('MobileLogoBoxOpenApp');
      } else {
        document.getElementById("carrousel").classList.remove('MobileLogoBoxOpenApp');
      }
    }
  },
  showPopupApp: async function () {
    var dontShow = localStorage.getItem('dowloadApp')

    if (!dontShow) {
      if (document.getElementsByClassName('BajateLaApp').length) {
        document.getElementsByClassName('BajateLaApp')[0].style.display = 'flex';
        if (document.getElementById("LOGINBOX")) {
          document.getElementById("LOGINBOX").classList.add('MobileLogoBoxOpenApp');
        } else {
          document.getElementById("carrousel").classList.add('MobileLogoBoxOpenApp');
        }

      }
    }
  },
  showAddressName: function (domicilio) {
    let calle = domicilio.calle;
    let altura = domicilio.altura;
    let piso = domicilio.piso ? domicilio.piso : '';
    let dpto = domicilio.dpto ? domicilio.dpto : '';
    let ciudad = domicilio.ciudad ? domicilio.ciudad : '';
    let barrio = domicilio.barrio;
    return calle + ' ' + altura + ' ' + piso + dpto + ', ' + barrio + ' ' + ciudad;
  },
  loggedIn: function () {
    return Auth.loggedIn();
  },
  getProfile: async function () {
    await Auth.getProfile();
  },
  getDomicilio: function () {
    return JSON.parse(localStorage.getItem(DOMICILIO_NAME));
  },
  setDomicilio: function (domicilio) {
    localStorage.setItem(DOMICILIO_NAME, JSON.stringify(domicilio));
  },
  removeAddressStorage: function () {
    localStorage.removeItem(DOMICILIO_NAME);
  },

  muestraFecha: function (date, nice = false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return nice ? [day, month, year].join('/') : [year, month, day].join('-');
  },
  addMinutes: function (date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  },
  restMinutes: function (date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  },

  updateDimensions: function (width) {
    if (width > 768) {
      return true
    } else {
      return false
    }
  },


  ClientAPI: {
    getAnimalBreeds: function (params) { return Auth.get(CLIENT_ANIMAL_BREEDS_GET, params); },
    getUserAddresses: function (params) { return Auth.get(CLIENT_PROFILE_ADDRESS_GET, params); },
    getBrandsHome: function (params) { return Auth.getPublic(CLIENT_HOME_BRANDS_GET, params); },
    getSearch: function (params) { return Auth.getPublic(CLIENT_SEARCH_GET, params); },
    getProductsRecents: function (params) { return Auth.getPublic(CLIENT_PRODUCTS_RECENTS_GET, params); },
    getProducts: function (params) { return Auth.getPublic(CLIENT_PRODUCTS_GET, params); },
    getProduct: function (params) { return Auth.getPublic(CLIENT_PRODUCT_GET, params); },
    getHomeSlider: function (params) { return Auth.getPublic(CLIENT_HOME_SLIDER_GET, params); },
    getOrders: function (params) { return Auth.get(CLIENT_ORDERS_GET, params); },
    getOrder: function (params) { return Auth.get(CLIENT_ORDER_GET, params); },
    getProgressOrders: function (params) { return Auth.get(CLIENT_PROGRESS_ORDERS_GET, params); },
    getSchedulePreOrder: function (params) { return Auth.getPublic(CLIENT_CHECKOUT_SCHEDULE_GET, params); },
    getPurchase: function (params) { return Auth.get(CLIENT_PURCHASE_GET, params); },
    getProfileMyProducts: function (params) { return Auth.get(CLIENT_PROFILE_PRODUCTS_GET, params); },
    getProfileData: function (params) { return Auth.get(CLIENT_PROFILE_GET, params); },
    getAnimals: function (params) { return Auth.get(CLIENT_ANIMAL_ALL_GET, params); },
    login: function (params) { return Auth.postPublic(CLIENT_LOGIN, params); },
    createAccount: function (params) { return Auth.postPublic(CLIENT_CREATE_ACCOUNT_POST, params); },
    forgotLogin: function (params) { return Auth.postPublic(CLIENT_FORGOT_LOGIN, params); },
    forgotCart: function (params) { return Auth.postPublic(CLIENT_FORGOT_CART, params); },
    postAddress: function (params) { return Auth.postPublic(CLIENT_ADDRESS_POST, params); },
    postAddressUpdate: function (params) { return Auth.postPublic(CLIENT_ADDRESS_UPDATE_POST, params); },
    profileChangePassword: function (params) { return Auth.post(CLIENT_PROFILE_CHANGE_PASS_POST, params); },
    profileChangeData: function (params) { return Auth.post(CLIENT_PROFILE_CHANGE_DATA_POST, params); },
    profileChangeMyProductsVariations: function (params) { return Auth.post(CLIENT_PROFILE_PRODUCTS_POST, params); },
    profileCancelOrder: function (params) { return Auth.post(CLIENT_PROFILE_CANCEL_ORDER_GET, params); },
    deleteAnimal: function (params) { return Auth.post(CLIENT_ANIMAL_POST, params); },
    getCards: function (params) { return Auth.get(CLIENT_CARDS_GET, params); },
    profileRepeatOrder: function (params) { return Auth.post(CLIENT_PROFILE_REPEAT_ORDER_GET, params); },
    saveAnimal: function (params) { return Auth.post(CLIENT_ANIMAL_SAVE_POST, params); },
    createOrder: function (params) { return Auth.postPublic(CLIENT_ORDER_POST, params); },
    setDurationProducts: function (params) { return Auth.postPublic(CLIENT_ORDER_DURATION_POST, params); },
    newsletterPost: function (params) { return Auth.postPublic(CLIENT_NEWSLETTER_POST, params); },
    sendBreeedError: function (params) { return Auth.get(SEND_BREED_ERROR, params); }
  },
  clearCart: async function () {
    await CartOliver.clearAllCart();
  },
  saveForgotCart: async function (email = false) {
    let cart = await CartOliver.getItems();
    if (email) {
      cart.email = email;
    }
    this.ClientAPI.forgotCart({ cart: cart }).then((response) => {
      //console.log('guardamos carrito abandonado', response);
      if (response && response.payload.cart_id) {
        //console.log('quiere guardar data response')
        cart.cart_id = response.payload.cart_id

        CartOliver.saveCartToStorage(cart);
      }
    })
  },

};

//ROUTES API CLIENT
const CLIENT_ENDPOINT = 'client_controller/';
const CLIENT_HOME_BRANDS_GET = CLIENT_ENDPOINT + 'brands/';
const CLIENT_HOME_SLIDER_GET = CLIENT_ENDPOINT + 'homeSlider/';
const CLIENT_SEARCH_GET = CLIENT_ENDPOINT + 'search/';
const CLIENT_PRODUCTS_RECENTS_GET = CLIENT_ENDPOINT + 'productsRecents/';
const CLIENT_PRODUCT_GET = CLIENT_ENDPOINT + 'product/';
const CLIENT_PRODUCTS_GET = CLIENT_ENDPOINT + 'productList/';
const CLIENT_LOGIN = CLIENT_ENDPOINT + 'login/';
const CLIENT_FORGOT_LOGIN = CLIENT_ENDPOINT + 'forgotLogin/';
const CLIENT_FORGOT_CART = CLIENT_ENDPOINT + 'forgotCart/';
const CLIENT_ADDRESS_POST = CLIENT_ENDPOINT + 'address/';
const CLIENT_ADDRESS_UPDATE_POST = CLIENT_ENDPOINT + 'addressupdate/';
const CLIENT_CHECKOUT_SCHEDULE_GET = CLIENT_ENDPOINT + 'checkoutSchedule/';
const CLIENT_ORDER_POST = CLIENT_ENDPOINT + 'checkoutPayment/';
const CLIENT_ORDER_DURATION_POST = CLIENT_ENDPOINT + 'checkoutDurationProducts/';
const CLIENT_CREATE_ACCOUNT_POST = CLIENT_ENDPOINT + 'createAccount/';
const CLIENT_ORDER_GET = CLIENT_ENDPOINT + 'order/';
const CLIENT_ORDERS_GET = CLIENT_ENDPOINT + 'myOrders/';
const CLIENT_PROGRESS_ORDERS_GET = CLIENT_ENDPOINT + 'progressOrders/';
const CLIENT_PURCHASE_GET = CLIENT_ENDPOINT + 'compraById/';
const CLIENT_PROFILE_CHANGE_PASS_POST = CLIENT_ENDPOINT + 'changePassword/';
const CLIENT_PROFILE_PRODUCTS_GET = CLIENT_ENDPOINT + 'myProducts/';
const CLIENT_PROFILE_PRODUCTS_POST = CLIENT_ENDPOINT + 'changeMyProductsVariation/';
const CLIENT_PROFILE_CHANGE_DATA_POST = CLIENT_ENDPOINT + 'changeProfileData/';
const CLIENT_PROFILE_GET = CLIENT_ENDPOINT + 'profile/';
const CLIENT_PROFILE_ADDRESS_GET = CLIENT_ENDPOINT + 'addresses/';
const CLIENT_PROFILE_CANCEL_ORDER_GET = CLIENT_ENDPOINT + 'cancelOrder/';
const CLIENT_PROFILE_REPEAT_ORDER_GET = CLIENT_ENDPOINT + 'repeatOrder/';
const CLIENT_ANIMAL_SAVE_POST = CLIENT_ENDPOINT + 'saveAnimal/';
const CLIENT_ANIMAL_ALL_GET = CLIENT_ENDPOINT + 'animals/';
const CLIENT_ANIMAL_POST = CLIENT_ENDPOINT + 'animalDelete/';
const CLIENT_NEWSLETTER_POST = CLIENT_ENDPOINT + 'newsletter/';
const CLIENT_ANIMAL_BREEDS_GET = CLIENT_ENDPOINT + 'breeds/';
const SEND_BREED_ERROR = CLIENT_ENDPOINT + 'notificationBreeds/';
const CLIENT_CARDS_GET = CLIENT_ENDPOINT + 'cards/';

//MOTIVOS DLEGACION
const MispichosVars = {
  MISPICHOS_PETSHOP_SERVICE_PHONE: '1151714343',
  MIN_AMOUNT_DNI: 5000,
  GMAPS_KEY: process.env.GMAPS_KEY // :D Variable key para el mapa
}


export default OliverHelper;
export { MispichosVars, ITEMS_TO_SHOW_PROD_HOME, OLIVER_PETS, urlRaiz };