import React, { Suspense } from "react";


import NavbarGoBack from "../Navbar/NavbarGoBack";
import Layout from "../Layout/Layout";

//import Home from "../Home/Home";
import HomeDesk from "../Home/HomeDesk_TCV";
import HomeMobile from "../Home/Home_TCV";

import NewAddress from "../Views/NewAddress";
import CartMobilleView from "../Views/CartMobilleView";
import Terminos, { Devoluciones, Envios, Privacidad } from "../Views/Terminos";

import PreguntasFrecuentes from '../Views/PreguntasFrecuentes'
import LoadingOmar from "../Tools/LoadingOmar";
import SearchView from "../Views/SearchView";
import { isMobile } from "react-device-detect";
//Lazy Loads
const IndividualProduct = React.lazy(() => import('../Views/IndividualProduct'));
const PurchaseOption = React.lazy(() => import('../Views/PurchaseOption'));
const PaymentMethod = React.lazy(() => import('../Views/PaymentMethod'));
const LayoutSimple = React.lazy(() => import('../Layout/LayoutSimple'));
const AltaMascotas = React.lazy(() => import('../Views/AltaMascotas'));
const PurchaseMade = React.lazy(() => import('../Views/PurchaseMade'));
const UserAccount = React.lazy(() => import('../Views/UserAccount'));
const ProductList = React.lazy(() => import('../Views/ProductList'));
const AddressList = React.lazy(() => import('../Views/AddressList'));
//const NewAddress = React.lazy(() => import('../Views/NewAddress'));
const SingUp = React.lazy(() => import('../Login/SingUp'));
const NewPet = React.lazy(() => import('../Views/NewPet'));
const PetProfile = React.lazy(() => import('../Views/PetProfile'));
//const CampoDePruebas = React.lazy(() => import('../Views/CampoDePruebas'));
const VetsList = React.lazy(() => import('../Views/VetsList'));
const SeguroBlock = React.lazy(() => import('../Views/SeguroPage'));
const Loading = React.lazy(() => import('../Tools/Loading'));
const MobileCreditCard = React.lazy(() => import('../Tools/MobileCreditCard'));
const MobileBrandList = React.lazy(() => import('../Mobile/MobileBrandList'));
const Payments = React.lazy(() => import('../Views/Payments'));


const URL_NAMING = {
  ENVIO: 'envio'
};

const DefaultComponent = () => {

  return (
    <div style={{ backgroundColor: "#1fa9e7", height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
      <div style={{ color: "white", textAlign: "center" }}>
        <img width={300} style={{ margin: 20 }} alt='Oliver_image_alt' src={require('../../IMG/LogoBlanco.png')} />
        <h1 style={{ margin: 20 }}>¡Estamos preparando una nueva experiencia para tus peludos!</h1>
        <h2>Vuelve pronto para conocerla</h2>
      </div>
    </div>
  )
}

export default {

  publicRoutes: [
    {
      path: "/",
      exact: true,
      nav: true,
      label: 'Inicio',
      main: () => <DefaultComponent />
    }
  ],
  routesLogged: [],
}

export { URL_NAMING };