import React, { Component } from 'react'

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons'
<FontAwesomeIcon icon={faLock} />
*/


export default class ArriveSafely extends Component {
  render() {
    return (
      <article className='ArriveSafely'>
        <span>¡Llega <strong>MAÑANA</strong>!</span>
        <legend className='RowCenter'>
          <div>COMPRA SEGURA</div>
          <img src={require('../../IMG/logo-mercadopago-manitos.png')} alt=""/>
        </legend>
      </article>
    )
  }
}
