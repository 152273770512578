import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTimes, faTrashAlt, faMinus, faPlus, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router-dom";
import CartOliver from "../../utils/CartOliver";
import OliverHelper, { OLIVER_PETS } from "../../utils/Oliverhelper";
import NumberMP from "../Tools/FormatNumber";
import AnalitycEvents from '../../utils/AnalyticsTag'
import translate from '../../languages'
import { isMobile } from "react-device-detect";
import { URL_NAMING } from "../Routes/Routes";

const Analitycs = new AnalitycEvents()


class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shoppingCart: [],

            sugerencias: [
                {
                    cat_nombre: "Alimento Balanceado",
                    linkfoto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/dev/mobile_mon-ami-gatos_452",
                    mar_nombre: "Mon Ami",
                    precio_minimo: 960,
                    pro_foto: "mon-ami-gatos_452",
                    pro_id: 452,
                    pro_nombre: "Gatos",
                    pro_nombre_completo: "Mon Ami Gatos Alimento Balanceado seco",
                    pro_description: "Alimento Balanceado seco",
                    pro_sticker_cantidad: 0,
                    producto: "Gatos Mon Ami",
                    variaciones: "1.4 Kgs",
                }
            ]
        }
    }

    async componentDidMount() {
        console.log("props", this.props)
        await this.createCart()
        document.addEventListener('didFocus', this.createCart());
    }

    createCart = async () => {
        let cart = await CartOliver.getItems();
        if (cart) {
            this.setState({ shoppingCart: cart });
        }
    }

    restOneToProduct = (variation) => {
        if (variation.cantOfThisProduct > 1) {
            variation.cantOfThisProduct = variation.cantOfThisProduct - 1;
            Analitycs.gtagRemoveToCart(variation)
            CartOliver.addProductToCart(variation);
            this.createCart();
        }
    }

    plusOneToProduct = (variation) => {
        variation.cantOfThisProduct = variation.cantOfThisProduct + 1;
        Analitycs.gtagAddToCart(variation)
        Analitycs.FBAddToCart(variation)
        CartOliver.addProductToCart(variation);
        this.createCart();
    }

    removeItemInCart = (variation) => {
        CartOliver.DeleteProdCart(variation);
        this.createCart();
    }


    redirectTo = () => {
        let domicilio = OliverHelper.getDomicilio();
        var location = ""
        if (domicilio !== null) {
            location = "carrito"
            this.props.history.push({ pathname: `/${URL_NAMING.ENVIO}`, state: { detail: location } });
        } else {
            location = window.location.href
            Analitycs.gTagAddressOpen({ "mobile": 'quiere_comprar' });
            if (isMobile) {
                this.props.history.push({ pathname: '/nueva-direccion', state: location });
            } else {
                this.props.toggleDrawer('dire');
            }
        }
    }
    goTo = () => {
        this.props.history.push('/productos')
    }

    render() {
        if (window.location.pathname === '/carrito/mobile/') {
            document.title = 'Carrito' + OLIVER_PETS
        }
        return (
            <section className='Carrito' >
                {this.props.showElement ?
                    <div>
                        <button onClick={() => this.props.showDrawer('carrito')}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    : (this.state.shoppingCart.products !== undefined && this.state.shoppingCart.products.length > 0 ?
                        <div>
                            <button onClick={() => this.props.showDrawer('carrito')}>
                                <FontAwesomeIcon icon={faShoppingCart} />
                            </button>
                            <span>{this.state.shoppingCart.products.length}</span>
                        </div>
                        :
                        <div>
                            {isMobile ?
                                <button className='OpenSearch' onClick={() => this.props.history.push('/Buscador')} >
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                                :
                                <button className='svg-disabled'>
                                    <FontAwesomeIcon icon={faShoppingCart} />
                                </button>
                            }

                        </div>
                    )
                }

                {this.state.shoppingCart.products !== undefined &&
                    <div onBlur={() => this.props.showDrawer('carrito')}>
                        <ShoppingCartProducts
                            redirectTo={this.redirectTo}
                            visibleCarrito={this.props.showElement}
                            cart={this.state.shoppingCart}
                            bntActive={true}
                            rest={this.restOneToProduct}
                            plus={this.plusOneToProduct}
                            remove={this.removeItemInCart}
                            goTo={this.goTo}
                        />
                    </div>
                }
            </section>
        )
    }
}


const ShoppingCartProducts = ({ visibleCarrito, cart, rest, plus, remove, redirectTo, goTo }) => (
    <div className={visibleCarrito ? 'BoxCarrito ShowElement' : 'BoxCarrito'} >
        <ul>
            {cart.products.map((eachProduct, i) =>
                <li key={i} className='ItemCarrito'>
                    <img alt='Oliver_image_alt' src={eachProduct.photo_mobile} />
                    <div className='ItemCarritoColum'>
                        <div className="CarritoHead">
                            <h4>
                                {eachProduct.pro_full_name}
                            </h4>
                            <p>
                                {eachProduct.var_name}
                            </p>
                        </div>
                        <div className='RowCenter'>
                            <b>
                                <NumberMP total={eachProduct.prv_price_real * eachProduct.cantOfThisProduct}
                                    prefix={'Subtotal: $'}
                                />
                            </b>
                            <div className='BotonesMasomenos'>
                                <div className='BotonMenos' onClick={() => rest(eachProduct)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </div>
                                <p className='BotonLabel'>
                                    {eachProduct.cantOfThisProduct}
                                </p>
                                <div className='BotonMas' onClick={() => plus(eachProduct)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='CarritoDeleteItem' onClick={() => remove(eachProduct)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                </li>
            )}
        </ul>
        {visibleCarrito && cart.products.length > 0 &&
            <div className='accionables'>
                <button className='Btn-Action btn-carrito btn-blue' onClick={() => goTo()}>
                    {translate.t('botonAgregarCarrito')}
                </button>
                <button onClick={() => redirectTo()} className='Btn-Action btn-carrito'>
                    {translate.t('titleSchedules')}
                    <br />
                    <p>
                        <NumberMP total={cart.totalAmountProd} prefix={'Total: $'} />
                    </p>
                </button>
            </div>
        }

        {visibleCarrito && cart.products.length === 0 &&
            <div className='CarritoVacio'>
                <h2>Tu carrito está vacío</h2>
                <p>¿No sabes que comprar?</p>
                <p>¡Cientos de productos te esperan!</p>
                <br />
                <br />
                <button className='Btn-Action btn-carrito' onClick={() => goTo()}>
                    Elegir productos para tu compra
				</button>
            </div>
        }
    </div>
)

const Suggestion = ({ sugerencias, plus }) => (
    <ol>
        {sugerencias.map((sugerencia, i) =>
            <li key={i} className='Card RowCenter SuggestionItem '>
                <img alt='Oliver_image_alt' src={sugerencia.photo_mobile} />
                <div className=' w100 ItemCarritoColum'>
                    <div className="CarritoHead">
                        <h4>
                            {sugerencia.mar_nombre}
                        </h4>
                        <p>
                            {sugerencia.pro_full_name} x {sugerencia.var_name}
                        </p>
                    </div>
                    <div className='RowCenter mt-1'>
                        <b className='w100'>
                            ${sugerencia.prv_price_real}
                        </b>
                        <button className='w100 Btn-Action Btn-Small' onClick={() => plus(sugerencia)}>
                            Agregar
                        </button>
                    </div>
                </div>
            </li>
        )}
    </ol>
)

const ReducedCart = ({ showDrawer, totalProd, total }) => (
    <div className='w100 TextCenter' onClick={() => showDrawer()}>
        <b><NumberMP total={total} prefix={'Total: $'} /></b> <br /> {totalProd} {totalProd > 1 ? ' Productos' : 'Producto'} <FontAwesomeIcon icon={faAngleUp} />
    </div>
)

export default withRouter(Cart)
export { ShoppingCartProducts, Suggestion, ReducedCart };