import React from 'react';
import BrandBubble from '../Tools/BrandBubble';
import OliverHelper from '../../utils/Oliverhelper';
import { isMobile } from 'react-device-detect';

export default class BrandsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marcas: []
    };
  }
  componentDidMount() {
    OliverHelper.ClientAPI.getBrandsHome({ full: 1 }).then(response => {
      //console.log('response', response);
      this.setState({ marcas: response.payload })
    })
  }
  render() {
    let clssName = !isMobile ? 'BrandContainer' : 'Mobile BrandContainer';

    console.log(' marcas >>>>>>> ', this.state.marcas)
    return (
      <section className={clssName}>
        <span className='BrandDivider' />
        <h1 style={{ marginTop: '40px' }} >Compra tus marcas favoritas</h1>
        <h5 style={{ marginBottom: '8px' }} >Envío gratis en CDMX</h5>
        {console.log(' marcas >>>>>>> ', this.state.marcas)}
        <ol className={this.state.marcas.length === 6 ? 'marcasCentradas ' : ''}>
          {this.state.marcas.map((brand, o) =>
            <li key={o}>
              <BrandBubble dataBrand={brand} />
            </li>
          )}
        </ol>
      </section>
    );
  }
}
