import React, { Suspense } from "react";
//import HomeNavbar from '../Navbar/HomeNavbar';
import HomeDrawerGeneric from "../Tools/HomeDrawerGeneric";
import SuperNav from "../Navbar/SuperNav";
import { isMobile } from "react-device-detect";
import Footer from "./Footer";
import LoadingOmar from "../Tools/LoadingOmar";
import ModalGeneric from '../Tools/ModalGeneric';

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      showDrawer: false,
      contentDrawer: '',
      showAddress: false,
      showSide: false,
      showCart: false,
      showLoginForm: false,
      // Modal
      showModalGeneric: false,
      modalTitle: '',
      modalBody: '',
    };
  }

  toggleDrawer = (contentDrawer, data, show) => {
    console.log("data", data)
    this.setState({ contentDrawer: contentDrawer, showDrawer: show ? show : !this.state.showDrawer, data: data })
  }

  showDrawer = (value, focus) => {
    console.log("Quiere show drawer", focus)
    if (value === 'direc') {
      var resultAddres = !this.state.showAddress
    } else if (value === 'side') {
      var resultside = !this.state.showSide
    } else if (value === 'carrito') {
      var resultcarrito = !this.state.showCart
    } else if (value === 'search') {
      resultcarrito = false
      resultAddres = false
    }

    this.setState({ showAddress: resultAddres, showSide: resultside, showCart: resultcarrito, searchFocus: focus })
  }

  toggleModal = () => this.setState({ showLoginForm: !this.state.showLoginForm })

  showModalGeneric = (title, body) => {
    this.setState({
      showModalGeneric: true,
      modalTitle: title,
      modalBody: body
    })
  }

  render() {
    //console.log('re render Home.js => el ancho de la pantalla es de: ', this.state.width);

    // Props
    const Children = this.props.children;

    return (
      <main>
        <SuperNav
          case={this.props.case}
          toggleDrawer={this.toggleDrawer}
          showDrawer={this.showDrawer}
          showAddress={this.state.showAddress}
          showSide={this.state.showSide}
          searchFocus={this.state.searchFocus}
          showCart={this.state.showCart}
          toggleModal={this.toggleModal}
          showLoginForm={this.state.showLoginForm}
        />

        <Suspense fallback={<LoadingOmar />}>
          <Children toggleDrawer={this.toggleDrawer} showDrawer={this.showDrawer} toggleModal={this.toggleModal} showModalGeneric={this.showModalGeneric} />
        </Suspense>

        {
          isMobile ?
            null
            :
            <HomeDrawerGeneric content={this.state.contentDrawer} data={this.state.data} toggleDrawer={this.toggleDrawer} showTheDrawerGeneric={this.state.showDrawer} showModalGeneric={this.showModalGeneric} />
        }

        {this.props.showFooter && <Footer />}
        <ModalGeneric
          closeModal={() => this.setState({ showModalGeneric: false })}
          showModal={this.state.showModalGeneric}
          title={this.state.modalTitle}
          body={this.state.modalBody}
        />
      </main>
    );
  }
}