import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { LogoAmerican, LogoMasterCard, LogoVisa } from '../Tools/OliverIcons';
import OliverHelper from '../../utils/Oliverhelper';
import Modal from '../Tools/ModalGeneric';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

const zoho = window.$zoho;

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newsletter_email: '',
      showModal: false,
      modalTitle: null,
      modalBody: null,
    }
  }

  handleChange = (e) => {
    this.setState({ newsletter_email: e.target.value })
  }

  sendNewsletter = () => {

    let params = {
      new_email: this.state.newsletter_email,
      new_interesa_perro: 1,
      new_interesa_gato: 1
    }
    OliverHelper.ClientAPI.newsletterPost(params).then((response) => {
      if (response.status) {
        this.setState({
          newsletter_email: null,
          showModal: true,
          modalTitle: "Perfecto",
          modalBody: "Te enviaremos informacion en base a tus intereses",
        })
      }
    })
  }

  toggleModal = () => [
    this.setState({ showModal: false })
  ]

  render() {
    return (
      <footer>
        <ul>
          <li>
            <h4>Sobre Nosotros</h4>
            <ol>
              <li onClick={() => this.props.history.push('/terminos-condiciones')}>
                Términos y Condiciones
              </li>
              {/**<li onClick={() => this.props.history.push('/politicas-privacidad')}>
                Política de Privacidad
              </li> */}
              <li onClick={() => this.props.history.push('/politicas-envios')}>
                Política de Envío
              </li>

              <li onClick={() => this.props.history.push('/politicas-devoluciones')}>
                Política de Devoluciones
              </li>
              <li onClick={() => zoho && zoho.salesiq.chat.start()}>
                Contáctanos
              </li>
            </ol>
          </li>
          <li>
            <h4>Atención a clientes</h4>
            <ol>
              <li>Lunes a Viernes: De 10:00 a 21:30</li>
              <li>Sábados: De 10:00 a 19:00</li>

              <li>+52 55 85262902</li>
              <li>ventas@oliver.pet</li>
              <li>Zamora 187, Condesa, CDMX, México</li>
            </ol>
          </li>
          <li>
            <h4>Descarga nuestras apps</h4>
            <ol>
              <li className='appStoreImg' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mispichos.clientes&hl=es_AR')}>
                <img alt='Oliver_image_alt' src={require('../../IMG/logo-google-store.png')} />
              </li>
              <li className='appStoreImg' onClick={() => window.open('https://apps.apple.com/us/app/mispichos/id1488224504?l=es')}>
                <img alt='Oliver_image_alt' src={require('../../IMG/logo-apple-store.png')} />
              </li>
            </ol>
          </li>
          <li>
            <h4>Newsletter</h4>
            <ol>
              <li>
                Únete a nuestro Newsletter
              </li>
              <li>
                <input type="mail" placeholder='Tu email' value={this.state.newsletter_email} onChange={this.handleChange} />
              </li>
              <li>
                <button className='w100 Btn-Action' onClick={() => this.sendNewsletter()}>
                  Suscribirse
                </button>
              </li>
            </ol>
          </li>
        </ul>
        <dl>
          <li className='OliverLogoFooter'
            style={{ cursor: 'pointer' }}
            onClick={() => window.location.href = 'https://mx.oliver.pet/'}
          >
            <svg version="1.1" width="1920" height="1079.967" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 685.5 382.7"
              style={{ "enable-background": '0 0 685.5 382.7' }} xml="preserve">
              <path fill="#414141" fillRule="evenodd" d="M473.7,282.6h-21v56.3h11.1v-18.5h9.9c10.9,0,19.2-8.4,19.2-18.9S484.6,282.6,473.7,282.6z M473.7,310h-9.9
              v-17.1h9.9c4.7,0,8.2,3.6,8.2,8.5C481.9,306.3,478.5,310,473.7,310z M605.8,327.1c0,8.7-7.6,12.9-16.2,12.9c-8,0-14-3.4-16.9-9.6
              l9-5.1c1.1,3.3,3.9,5.2,7.9,5.2c3.3,0,5.5-1.1,5.5-3.5c0-5.9-20.7-2.7-20.7-16.8c0-8.2,7-12.8,15.3-12.8c6.5,0,12.1,3,15.3,8.5
              l-8.8,4.8c-1.2-2.6-3.5-4.1-6.4-4.1c-2.6,0-4.7,1.1-4.7,3.3C585.1,316,605.8,312.3,605.8,327.1z M558.3,298.6h9.1v10h-9.1v16.7
              c0,4.3,3.1,4.4,9.1,4.1v9.4c-14.6,1.6-19.5-2.7-19.5-13.5v-16.7h-7v-10h7v-8.1l10.4-3.1V298.6z M516.4,297.5
              c-12.6,0-21.2,9.2-21.2,21.2c0,12,8.5,21.2,22,21.2c7.7,0,13.8-3.1,17.5-8.6l-8.4-4.8c-1.8,2.3-5,4-9,4c-5.5,0-9.9-2.3-11.3-7.6
              h30.3c0.2-1.4,0.4-2.7,0.4-4.3C536.7,306.9,528.3,297.5,516.4,297.5z M505.9,315c1.2-5.1,5-8.1,10.5-8.1c4.3,0,8.7,2.3,10,8.1H505.9
              z M270.6,252.6c-6.1,3.4-13.4,4.6-20,4.6c-20.8,0-27.6-12.4-27.6-25.8v-49.6c0-50.5-1.7-72.9-2.3-79.1c-0.1-1,0.7-1.8,1.7-1.8h35.8
              c0.9,0,1.7,0.8,1.7,1.7v127c0,7.9,2.7,14.5,10.9,20C271.7,250.4,271.7,252,270.6,252.6z M319.5,142.5v110.8c0,0.9-0.8,1.7-1.7,1.7
              h-33.7c-0.9,0-1.7-0.8-1.7-1.7v-32c0-50.4-1.7-72.5-2.3-78.6c-0.1-1,0.7-1.9,1.7-1.9h36C318.7,140.8,319.5,141.5,319.5,142.5z
              M446.4,143L410,253.8c-0.2,0.7-0.9,1.2-1.6,1.2h-41.8c-0.7,0-1.4-0.5-1.6-1.2L329.6,143c-0.3-1.1,0.5-2.2,1.6-2.2h35.5
              c1.2,0,2.2,0.8,2.5,1.9l20.1,80.4c0,0,15.8-56.8,21.5-80.4c0.3-1.1,1.3-1.9,2.5-1.9h31.6C446,140.8,446.8,141.9,446.4,143z
              M636.9,184.1c-0.9,1-2.5,0.7-3-0.6c-2.1-5.5-7.3-9.8-14.6-9.8c-6.5,0-14.9,5.2-14.9,15.3v64.3c0,0.9-0.8,1.7-1.7,1.7h-33.5
              c-0.9,0-1.7-0.8-1.7-1.7c0,0,0.8-47.2,0.8-70.7c0-23.4-1.7-39.7-1.7-39.7c0-1.3,0.7-2.2,1.7-2.2h11.7c6.3,0,12,3.6,14.7,9.3l2.4,5
              c4.8-6.9,10.9-12.8,21.6-12.8c10.1,0,21.2,6.9,23.6,21.3C643.5,170,641.5,178.7,636.9,184.1z M504.1,257.2
              c33.1,0,48.7-18.9,44.9-36.5c-0.3-1.3-1.9-1.7-2.9-0.8c-4.8,4.8-14.9,11.5-30.3,11.5c-14.7,0-30.3-6.1-32.3-28.3h56.3
              c7.5,0,13.6-6.1,13.6-13.6c0-26-14.9-51.1-49.7-51.1c-33.7,0-58.1,23.7-58.1,59.5C445.6,227.4,462.1,257.2,504.1,257.2z
              M501.9,163.6c11.3,0,17.4,8.1,17.4,17.9v2.5h-36.4C482.9,173.3,489.4,163.6,501.9,163.6z M321.2,110.1c0,11.8-9.6,21.4-21.4,21.4
              c-11.8,0-21.4-9.6-21.4-21.4s9.6-21.4,21.4-21.4C311.7,88.7,321.2,98.3,321.2,110.1z M196.2,44c-8.2-2.9-15.2-0.6-19.6,4.9
              c-5.5,7-9.7,16.4-10.8,30.9c-11.4-6.8-23.7-9.8-34.8-9.8c-11.1,0-23.4,3-34.8,9.8C95,65.2,90.9,55.8,85.4,48.8
              C81.1,43.4,74,41.1,65.8,44c-6.1,2.1-15.6,7.9-20.9,22c-4.3,11.4-1.4,19.7,2.2,22.4c3.6,2.7,10.8,1,19-4.5c6.4-4.3,6.9-5.3,7.7-2.4
              c1.9,7.3-2.9,22.8-7.8,34.2c-5.2,12.1-8.8,28.4-8.8,47.4c0,68.7,42.1,93.2,73.8,93.2c31.7,0,73.8-24.5,73.8-93.2
              c0-19.1-3.6-35.4-8.8-47.4c-4.9-11.3-9.7-26.8-7.8-34.2c0.8-3,1.3-1.9,7.7,2.4c8.2,5.5,15.4,7.3,19,4.5c3.6-2.7,6.4-11,2.2-22.4
              C211.8,51.9,202.3,46.1,196.2,44z M131,226.2c-23.4,0-36.4-33.7-36.4-63.1c0-29.4,13-63.1,36.4-63.1c23.4,0,36.4,33.7,36.4,63.1
              C167.4,192.5,154.4,226.2,131,226.2z"/>
            </svg>

          </li>
          <li>
            Síguenos <br />
            <div className="RowCenter footerLogos">
              <img alt='Oliver_image_alt' src={require('../../IMG/fb.png')} onClick={() => window.open('https://www.facebook.com/oliverpetsmx')} />
              <img alt='Oliver_image_alt' src={require('../../IMG/tw.png')} onClick={() => window.open('https://twitter.com/MisPichos')} />
              <img alt='Oliver_image_alt' src={require('../../IMG/ig.png')} onClick={() => window.open('https://www.instagram.com/mispichos/?hl=es-la')} />
            </div>
          </li>
          <li>
            Aceptamos
            <div className="RowCenter footerLogos">
              <LogoVisa />
              <img alt='Oliver_image_alt' src={require('../../IMG/logo-mercadopago.png')} />
              <LogoMasterCard />
              <LogoAmerican />
              <FontAwesomeIcon icon={faMoneyBillWave} color='green' size={'2x'} />
            </div>
          </li>
        </dl>

        <Modal
          closeModal={this.toggleModal}
          showModal={this.state.showModal}
          title={this.state.modalTitle}
          body={this.state.modalBody}

        />
      </footer >
    )
  }
}

export default withRouter(Footer);