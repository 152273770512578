import React from 'react';
import Modal from '../Tools/Modal';
import AuthService from '../../utils/AuthService';
import OliverHelper from '../../utils/Oliverhelper';

const Auth = new AuthService();

export default class LoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLogged: false,
      user: {},
      totalOrder: 0
    };
  }


  componentDidMount() {
    this.getUserLogged()
  }


  getUserLogged = async () => {
    var logeado = Auth.loggedIn()
    if (logeado) {
      var compra = 0
      var user = Auth.getProfile()
      await OliverHelper.ClientAPI.getProgressOrders().then((response) => {
        console.log("response mas sencillo", response)
        if (response.payload.progreso) {
          return compra = response.payload.cantidad
        }
      })
      this.setState({ userLogged: true, user: user, totalOrder: compra })
    } else {
      this.setState({ userLogged: false })
    }

  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }



  render() {
    var text = this.capitalize(this.state.user.nombre)
    return (
      this.state.userLogged === false ?
        <article>
          <button className='LoginButton' onClick={() => this.props.toggleModal()}>
            Mi Cuenta
          </button>
          <Modal closeModal={this.props.toggleModal} showModal={this.props.showLoginForm} type={'loginButton'} />
        </article>
        :
        <article>
          {this.state.totalOrder > 0 ?
            <button onClick={() => this.props.toggleDrawer('myAccount')} className='LoginButton'>
              Hola {text}, hay {this.state.totalOrder} {this.state.totalOrder > 1 ? 'pedidos' : 'pedido'} en curso.
          </button>
            :
            <button onClick={() => this.props.toggleDrawer('myAccount')} className='LoginButton'>
              Hola {text}!
          </button>
          }
        </article>
    );
  }
}

/*

        <article>
          <button className='LoginButton'>
            {this.state.user.nombre}
          </button>
        </article>

*/