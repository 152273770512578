import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default class LoadingOmar extends React.Component {
  render() {
    return (
      <section
        className={'ColumnCenter JustifyCenter AlignCenter Loading Loading-' + (this.props.type ? this.props.type : 'Normal')}
      >
        <article>
          <FontAwesomeIcon icon={faSpinner} />
          {this.props.labelText ? <h5>{this.props.labelText}</h5> : null}
        </article>
      </section>
    )
  }
}