import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class InputText extends React.Component {
  render() {
    return (
      <div className="form-group">
        <FontAwesomeIcon icon={this.props.inputIcon ? this.props.inputIcon : 'faEnvelope'} />
        <input name="text" type="text" required />
        <label>{this.props.labelText}</label>
      </div>
    );
  }
}



const MyImage = ({ alt, image }) => (

  <LazyLoadImage
    className={image.class}
    alt={alt}
    height={image.height}
    src={image.src} // use normal <img> attributes as props
    width={image.width} />

);

class InputMail extends React.Component {
  render() {
    return (
      <div className="form-group">
        <FontAwesomeIcon icon={faEnvelope} />
        <input name="mail" type="mail" required />
        <label>E-mail</label>
      </div>
    );
  }
}

class InputPassword extends React.Component {
  render() {
    return (
      <div className="form-group">
        <FontAwesomeIcon icon={faLock} />
        <input name="password" type="password" required />
        <label>Contraseña</label>
        <a href='https://mispichos.com'>¿Has olvidado la contraseña?</a>
      </div>
    );
  }
}

class InputSubmit extends React.Component {
  render() {
    return (
      <button className={this.props.btnActive ? 'Btn BtnPrimary w80' : 'Btn BtnDisable w80'}>
        {this.props.labelText}
      </button>
    );
  }
}

export { InputMail, InputPassword, InputSubmit, MyImage };