import React from 'react';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { OliverLogoNew } from '../Tools/OliverIcons';


class NavbarGoBack extends React.Component {
  goToHome = () => {
    if (window.location.pathname !== '/' && window.location.pathname !== '/carrito/para-pagar/') {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <header className={'fixedNavbar'}>
        <nav className={'NavbarGoBack RowCenter'}>
          <button type="button" onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <OliverLogoNew reloadPage={this.goToHome} />
          <div />
        </nav>
      </header>
    );
  }
}

export default withRouter(NavbarGoBack);