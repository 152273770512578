import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faIdCard, faMobileAlt/*, faShieldAlt, faExclamationTriangle*/ } from '@fortawesome/free-solid-svg-icons'
import { AlertSimple } from '../Tools/Alert';
import LoadingOmar from '../Tools/LoadingOmar';


import AuthService from '../../utils/AuthService';
import OliverHelper, { OLIVER_PETS } from '../../utils/Oliverhelper';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
const Auth = new AuthService();



class SeguroPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        user_name: 'Roberto',
        user_lastname: 'Carlos',
        email: 'RobertoCarlos@lorem.com',
        dni: '',
      },
      slideToSee: 'Primary',
      loading: true,
      activeSlide: false,

      animals: [],

    }
  }


  /*componentDidMount = async () => {
    //window.scrollTo(0, 0);
    if (Auth.loggedIn()) {
      var user = Auth.getProfile()
      await OliverHelper.ClientAPI.getAnimals().then(response => {
        if (response.payload.data) {
          this.setState({ animals: response.payload.data, user: user }, () => console.log("Response del get animals", this.state.animals))
        }
      })
    } else {
      this.redirectLogin()
    }
  }*/


  redirectLogin = () => {
    if (isMobile) {
      this.props.history.push('/registro')
    } else {
      if (this.props.toggleModal) {
        this.props.toggleModal()
      }
    }
  }

  setUserState = (newValue, indexName) => {
    var auxUser = this.state.user;
    auxUser[indexName] = newValue;
    this.setState({ user: auxUser });
  }

  MoveToSecondary = (value) => {
    this.setState({ slideToSee: 'Secondary', viewToShow: value })
    setTimeout(() => this.setState({ loading: false, activeSlide: true }), 3000)
    //console.log(':D Hizo click')
  }

  render() {
    document.title = 'Seguro' + OLIVER_PETS
    return (
      <section className='SeguroPage'>

        <div className="SlideBox">
          <article className={this.state.slideToSee === 'Primary' ? 'ShowElement PrimaryBox' : 'PrimaryBox'}>

            <SeguroBlock />
            {/**<ProtectedPetList
              data={this.state.animals}
              loading={this.state.loading}
              viewToShow={this.state.viewToShow}
              MoveToSecondary={this.MoveToSecondary}
            /> */}

          </article>
          <article className={this.state.slideToSee === 'Secondary' ? 'SecondaryBox' : 'SecondaryBox ShowElement'}>
            {this.state.loading ?
              <LoadingOmar labelText={'Conectando con satelite'} />
              :
              <div>
                <SeguroForm
                  data={this.state.user}
                  setUserState={this.setUserState}
                />

                <SelectPet data={this.state.animals} />
              </div>
            }
          </article>
        </div>
      </section>
    )
  }
}

const SeguroBlock = () => {
  document.title = 'Seguro' + OLIVER_PETS
  return (
    <div className='Card SeguroBlock'>
      <img
        alt="Logo de la veterinaria"
        src={require('../../IMG/icono_seguro.png')}
      />
      <h4>
        ¡Ya falta poco!
      </h4>
      <p>
        Está llegando el primer seguro para mascotas 100% digital
      </p>
    </div>
  )
}
/*
const ProtectedPetList = ({ data, MoveToSecondary }) => (
  <ol className='ProtectedPetList'>
    {data.map((pet) =>
      <li key={pet.id} className='Card RowCenter SpaceBetween' onClick={() => MoveToSecondary(pet.name)}>
        <span>
          {pet.name}
        </span>
        {pet.protegido ?
          <div className='RowCenter Primary-Text'>
            <b>{pet.plan}</b>
            <FontAwesomeIcon icon={faShieldAlt} className='AfixMobilIcon' />
          </div>
          :
          <div className='RowCenter Danger-Text'>
            <b>Sin Proteccion</b>
            <FontAwesomeIcon icon={faExclamationTriangle} className='AfixMobilIcon' />
          </div>
        }
      </li>
    )}
  </ol>
)
*/


const SeguroForm = ({ data, setUserState }) => (
  <div className='Card'>
    <h4>
      Seguro de cobertura Oliver
    </h4>
    <fieldset className='FormGroup FormGroupDuo FormGroupDisabled'>
      <FontAwesomeIcon icon={faUser} />
      <input
        type="text"
        placeholder='Nombre'
        value={data.user_name}
      //onChange={(name) => setDataState(name.target.value, 'user_name')}
      />
      <input
        type="text"
        placeholder='Apellido'
        value={data.user_lastname}
      //onChange={(lastname) => { setDataState(lastname.target.value, 'user_lastname') }}
      />
    </fieldset>
    <fieldset className='FormGroup FormGroupDisabled'>
      <FontAwesomeIcon icon={faEnvelope} />
      <input
        placeholder='Correo electronico'
        value={data.email}
      //onChange={(email) => setUserState(email.target.value, 'email')}
      />
    </fieldset>
    <fieldset className='FormGroup'>
      <FontAwesomeIcon icon={faIdCard} />
      <input
        placeholder='DNI / LC / CD'
        value={data.dni}
        onChange={(dni) => setUserState(dni.target.value, 'dni')}
      />
    </fieldset>
    <fieldset className='FormGroup FormGroupDuo'>
      <FontAwesomeIcon icon={faMobileAlt} className='AfixMobilIcon' />
      <input
        placeholder='011'
        value={data.cod_area}
        onChange={(phone) => { setUserState(phone.target.value, 'cod_area') }}
      />
      <input
        placeholder='15 2345-6789'
        value={data.phone}
        onChange={(phone) => { setUserState(phone.target.value, 'phone') }}
      />
    </fieldset>
    <AlertSimple type={'Warning'} labelText={'Completa todos los campos para poder continuar'} />
  </div>
)

const SelectPet = ({ data }) => (
  <div className='Card SelectPet'>
    {data.length > 1 ?
      <div>
        <h4>
          Elegi tu mascota
        </h4>

        <ol className='RowCenter JustifyCenter mt-1'>
          {data.map((pet) =>
            <li key={pet.id}>
              <img className='SelectPetImage' src={pet.photo} alt={pet.name} />
            </li>
          )}
        </ol>

        <p>Con los planes de cobertura de Oliver, tu mascota...</p>
        <SelectPlan />

      </div>
      :
      <div>
        <h4>Protege a {data[0].name}</h4>
        <p>Con los planes de cobertura de Oliver, tu mascota...</p>
        <SelectPlan />
      </div>
    }
  </div>
)

const SelectPlan = () => (
  <div>
    <div className="RowCenter SpaceBetween SelectPlan">
      <div className="w100 SelectPlanOption">
        Standar
        <br />
        $400/mes
      </div>
      <div className="w100 SelectPlanOption active">
        Premium
        <br />
        $600/mes
      </div>
    </div>
    <button className='w100 Btn-Link mt-1 p2 mbn-1'>Ver planes</button>
  </div>
)

export default withRouter(SeguroPage)
export { SeguroBlock };