import React, { Component } from 'react'
import SearchBox from '../Search/SearchBox'

export default class SearchView extends Component {
  render() {
    return (
      <section className='SearchView'>
        <SearchBox/>
      </section>
    )
  }
}
