import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class ModalGeneric extends React.Component {

    render() {

        if (!this.props.showModal) return null;

        return (
            <section className={'CustomModal Open'}>
                <article className='ModalBody'>
                    <button className='BtnCloseModal' onClick={() => { this.props.closeModal() }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 style={{ textAlign: 'center', padding: '1rem 0 1rem 0' }}>{this.props.title}</h2>
                    <h4>{this.props.body}</h4>
                </article>
            </section>
        )
    }
}

export default ModalGeneric

