const RECENT = 'oliver_recent';

const ProductRecent = {

    addProductToRecent: async function (product) {
        var recentStorage = await localStorage.getItem(RECENT);

        if (recentStorage) {
            var recentAux = JSON.parse(recentStorage);
            const index = this.findProductIndexOfRecent(product, recentAux)
            //no existe el producto en el carrito
            if (index === null) {
                recentAux.unshift(product);
                if (recentAux.length > 10) recentAux.pop()
            }

        } else {
            var recentAux = [];
            recentAux.push(product);
        }

        this.saveToStorage(recentAux);
    },

    findProductIndexOfRecent: function (productIdToSearch, recent) {
        var indice = null;
        recent.map((eachProduct, index) => {
            if (eachProduct == productIdToSearch) {
                indice = index;
            }
        })
        return indice;
    },
    saveToStorage: function (recent) {
        localStorage.setItem(RECENT, JSON.stringify(recent));
    },
    getItems: async function () {
        return JSON.parse(await localStorage.getItem(RECENT))
    }
}

export default ProductRecent;