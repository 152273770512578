import React from "react";
import {
  BrowserRouter as Router, Route, Link, Switch, useLocation
  //  Redirect,
} from "react-router-dom";
import Routes from "./Components/Routes/Routes";
import AuthService from "./utils/AuthService"

const Auth = new AuthService();

export default function NestingExample() {
  //console.log("entrando en app js")
  const routesByRol = Auth.filterRoutes(Routes);
  return (
    <Router>
      <Switch>
        {routesByRol.map((route, index) => (
          // Render more <Route>s with the same paths as
          // above, but different components this time.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.main />}
          />
        ))}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
}

const NoMatch = () => {
  let location = useLocation();

  return (
    <section className={'PaginaNoEncontrada'}>
      <h1>¡Ups!</h1>
      <br />
      <p>
        ¿Estas perdido? <Link to="/">Vuelve al Inicio</Link>
      </p>
      <br />
      <img alt='404 - Pagina no encontrada D:' src={require('./IMG/fogg-page-not-found-1.png')} />
      <code>{location.pathname}</code>
    </section>
  );
}