import React from 'react';
import NumberFormat from 'react-number-format';


const NumberMP = ({ total, prefix }) => (

    <NumberFormat value={total}
        displayType={'text'}
        thousandSeparator="."
        decimalSeparator=","
        prefix={prefix}
        renderText={value => value}
    />

)

export default NumberMP 