import React from 'react';
import { withRouter } from 'react-router-dom';
import Cart from '../Navbar/Cart';


class CartMobileView extends React.Component {
  render() {

    return (
      <section className='CartMobileView'>
        <Cart showElement={true} />
      </section>
    )
  }
}

export default withRouter(CartMobileView);