import React from 'react';
import { country } from '../../utils/mercadoPago';
import { OLIVER_PETS } from '../../utils/Oliverhelper';
import { Veterinary } from '../Tools/LittleProductBlock';

export default class VetsList extends React.Component {
  render() {
    const DATA = [
      {
        id: '1',
        title: 'Veterinaria V Q Pet Shop',
        domi: ' Godoy Cruz 2894',// -34.5768219,-58.4237718
        mapa: 'https://goo.gl/maps/zxumyDLyRGRG7BU26',
        horario: '24hs Todos los Dias',
        telefono: '4777-6914'
      },
      {
        id: '2',
        title: 'Leocan Veterinaria',
        domi: ' Manuel Ugarte 2226', // -34.5555923,-58.4598441
        mapa: 'https://goo.gl/maps/B7kc9nrwYbdiJQd28',
        horario: '24hs Todos los Dias',
        telefono: '4785-5566'
      },
      {
        id: '3',
        title: 'Centro Médico Veterinario Belgrano',
        domi: ' Amenábar 2214',// -34.5620897,-58.4596735
        mapa: 'https://goo.gl/maps/SkFw8kDKCUHWYCrJA',
        horario: '24hs Todos los Dias',
        telefono: '4782-9079'
      },
      {
        id: '4',
        title: 'ANIMED',
        domi: ' Vidal 2301',// -34.5621989, -58.4619123
        mapa: 'https://goo.gl/maps/ujw52mfn3JxQHEEa7',
        horario: '24hs Todos los Dias',
        telefono: '4784-0181'
      },
      {
        id: '5',
        title: 'Veterinaria Panda',
        domi: ' Ruiz Huidobro 4771',// -34.555036,-58.494591
        mapa: 'https://goo.gl/maps/xxNY2MiKLULFASmg9',
        horario: '24hs Todos los Dias',
        telefono: '5263-0176'
      },
      {
        id: '6',
        title: 'Centro Veterinario Paunero',
        domi: ' French 2673',// -34.5895305,-58.4017048
        mapa: 'https://goo.gl/maps/ZQYt4Jb81GhTHEh56',
        horario: '24hs Todos los Dias',
        telefono: '4802-6837'
      },
      {
        id: '7',
        title: 'Veterinaria 24hs Budhapets ',
        domi: ' Av. Tte. Gral. Donato Álvarez 2077',// -34.603688,-58.46427
        mapa: 'https://goo.gl/maps/T6ntcuVU2yh4ppW8A',
        horario: '24hs Todos los Dias',
        telefono: '4583-5595'//URGENCIAS 1151799970
      },
      {
        id: '8',
        title: 'Clivet Clinica Integral Veterinaria',
        domi: ' Martiniano Leguizamón 4177',// -34.6778726,-58.4762794
        mapa: 'https://goo.gl/maps/sevdBEwnftAAKQh96',
        horario: '24hs Todos los Dias',
        telefono: '4638-1783'
      },
      {
        id: '9',
        title: 'CLÍNICA VETERINARIA "DEL LABRADOR" ',
        domi: ' Av. Corrientes 4545',// -34.6022279 -58.429137
        mapa: 'https://goo.gl/maps/dsfCqpo5RQ1imoXv5',
        horario: '24hs Todos los Dias',
        telefono: '4863-0700'
      },
      {
        id: '10',
        title: 'Centro Asistencial Veterinario San Marcos S.A. MEDICINA VETERINARIA PRIVADA ',
        domi: ' Av. Díaz Vélez 4730',// -34.608866 -58.433346
        mapa: 'https://goo.gl/maps/4oScfTHBAs5UgbWw5',
        horario: '24hs Todos los Dias',
        telefono: '4981-1873'
      },
    ];

    const DATAMX = [
      {
        id: '1',
        title: 'VETME',
        domi: 'Pachuca #7, Condesa, Cuauhtémoc, CDMX',
        mapa: 'https://goo.gl/maps/AkMBdNKpeqA4S2MP6',
        horario: '24hs Todos los Dias',
        telefono: '55-5286-2843'
      },
      {
        id: '2',
        title: 'PET YOU',
        domi: 'Av. Homero 1416 Polanco, Ciudad de México',
        mapa: 'https://g.page/PetYouPolanco?share',
        horario: '24hs Todos los Dias',
        telefono: '555-290-2928'
      },
      {
        id: '3',
        title: 'Dog City Pet',
        domi: 'Calle Lago Ginebra 145',
        mapa: 'https://g.page/dogcitypethospital?share',
        horario: '24hs Todos los Dias',
        telefono: '5220-7805'
      },
      {
        id: '4',
        title: 'CENTRO VETERINARIO GRANADA',
        domi: 'Calle General Juan Cano 89',
        mapa: 'https://goo.gl/maps/vEJwkwGv8zusEyvAA',
        horario: '24hs Todos los Dias',
        telefono: '55-5277-2497'
      },
      {
        id: '5',
        title: 'Centro Veterinario Dr. Cardona',
        domi: 'Monte Athos 125E',
        mapa: 'https://goo.gl/maps/2yZ9CFGmDxapkEWH8',
        horario: '24hs Todos los Dias',
        telefono: '55-5202-2216'
      },
      {
        id: '6',
        title: 'CLINICA VETERINARIA DR MALVIDO',
        domi: 'PRADO NORTE 403/LOC-A',// -34.5895305,-58.4017048
        mapa: 'https://goo.gl/maps/STs5StUzd34U3jsi7',
        horario: '24hs Todos los Dias',
        telefono: '55-5202-9526'
      },
      {
        id: '8',
        title: 'ZOOTECA',
        domi: 'Av de las Fuentes 10',
        mapa: 'https://goo.gl/maps/gFy3nnvhbRdQzYdo8',
        horario: '24hs Todos los Dias',
        telefono: '55-5294-2359'
      },
      {
        id: '9',
        title: 'APLIVET CENTRO MEDICO VETERINARIO',
        domi: 'Calle 26 de Marzo 3',
        mapa: 'https://goo.gl/maps/Gn9T7zfMLcn91t2d9',
        horario: '24hs Todos los Dias',
        telefono: '55-5294-9290'
      },
      {
        id: '10',
        title: 'K-LEV VET',
        domi: 'Presa Angostura 29',// -34.608866 -58.433346
        mapa: 'https://goo.gl/maps/ExHPD5Z5Xaox8oEj7',
        horario: '24hs Todos los Dias',
        telefono: '55-6840-0737'
      },
    ];

    var data_map = []
    if (country === 'MLA') {
      data_map = DATA
    } else {
      data_map = DATAMX
    }
    document.title = 'Veterinarias' + OLIVER_PETS
    return (
      <section className='Canvas VetsList'>
        <ol>
          {data_map.map((data_vet, i) =>
            <li key={i}>
              <Veterinary data={data_vet} />
            </li>
          )}
        </ol>
      </section>
    )
  }
}