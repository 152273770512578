import React, { Component } from 'react'
import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.imagePath =
  '../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default class OliverMap extends Component {
  state = {
    lat: "",
    lng: "",
    zoom: 16,
  }

  componentDidMount() {
    this.getFatherProps();
  }

  componentWillReceiveProps(next_props) {
    // Cambio de Tab

    if (next_props.lat !== this.props.lat && next_props.long !== this.props.long) {
      this.setState({ lat: next_props.lat, lng: next_props.long })
    }
  }

  getFatherProps = () => {
    var lati = this.props.lat
    var long = this.props.long
    //console.log("lat y long", lati, long)
    this.setState({ lat: lati, lng: long })
  }


  handleClick = async (event) => {
    const { lat, lng } = event.latlng

    console.log("INFO: ---> ", this.props.google)

    var geocoder = new this.props.google.maps.Geocoder;

    console.log("INFO 2: ---> ", geocoder)

    var direccion = null;

    var latLng = {
      lat: lat,
      lng: lng
    };

    await geocoder.geocode({ 'location': latLng }, function (results, status) {
      console.log("RESULT:", results)
      console.log("STATUS:", status) //string

      // si la solicitud fue exitosa
      if (status === "OK") {
        // si encontró algún resultado.
        if (results[0]) {
          //Asignar direccion
          direccion = (results[0]);
        }
      }
      console.log("esta es la data de la direccion: ", direccion)
    });
    setTimeout(() => this.props.geocoderByLat(direccion), 1000)
    this.setState({ lat: lat, lng: lng })
  }


  render() {
    console.log("esta es la data de la direccion", this.props.google.maps.Geocoder)
    const position = [this.state.lat, this.state.lng]
    return (

      <Map
        center={position}
        zoom={this.state.zoom}
        style={{ width: "100%", height: '100%' }}
        onClick={this.props.moveMap ? this.handleClick : console.log("no")} // <-- call handleClick()
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={position}
        >
          <Popup>
            Son Konum
        </Popup>

        </Marker>
      </Map>
    )
  }
}