import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, /** faTrashAlt, */faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect';
import { MyImage } from './Inputs';
import NumberMP from './FormatNumber';

class LittleProductBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: this.props.dataProducto,
      variations: this.props.dataProducto.array_variaciones,
      variedad_select: [],
      photo: '',
    }
  }

  componentDidMount() {
    this.lastedElements();
  }


  componentWillReceiveProps(next_props) {

    // Nuevo producto
    if (next_props.dataProducto !== this.props.dataProducto) {
      this.setState({
        producto: next_props.dataProducto,
        variations: next_props.dataProducto.array_variaciones,
        variedad_select: [],
      }, () => {
        // 
        this.lastedElements();
      });
    }
  }

  dimensions = () => {
    var aux = '', replaced = '';
    if (isMobile && this.state.variedad_select.linkfoto !== undefined) {
      aux = this.state.variedad_select.linkfoto
      this.setState({ photo: aux }, () => this.discount())
    } else {
      aux = this.state.variedad_select.linkfoto
      replaced = aux.replace("desktop_", "mobile_")
      this.setState({ photo: replaced }, () => this.discount())
    }
  }

  lastedElements() {
    var Aux = this.state.variations
    if (this.state.variations !== undefined) {
      var last_element = Aux[Aux.length - 1];
      this.setState({ variedad_select: last_element }, () => this.dimensions())
    }
  }

  selectVariation(variacion) {
    this.setState({ variedad_select: variacion }, () => this.dimensions(), this.discount())
  }

  transformStringToUriString = (value) => {
    let newValue = value
    newValue = newValue.replace(/ /g, "-")
    newValue = newValue.replace(/\//g, "")
    return newValue;
  }

  createLinkToProduct = () => {

    let link = this.getLinkToProduct();
    this.props.history.push(link);
    if (window.location.pathname !== '/' && window.location.pathname !== '/productos' && !isMobile) {
      return this.props.history.go();
    }

  }

  getLinkToProduct = () => {
    let catName = this.props.dataProducto.cat_nombre ? this.transformStringToUriString(this.props.dataProducto.cat_nombre) : null;
    let brandName = this.props.dataProducto.mar_nombre ? this.transformStringToUriString(this.props.dataProducto.mar_nombre) : null;
    let prodName = this.props.dataProducto.pro_nombre ? this.transformStringToUriString(this.props.dataProducto.pro_nombre) : null;
    let proId = (this.props.dataProducto.pro_id);
    let prv_id = (this.state.variedad_select.prv_id);
    return '/productos/' + catName + '/' + brandName + '/' + prodName + '/' + proId + '/' + prv_id;
  }

  discount = () => {
    if (this.state.variations !== undefined) {
      var dif = 0
      var calc = 0
      var result = 0
      this.state.variations.map((variacion, i) => {
        if (variacion.prv_id === this.state.variedad_select.prv_id) {
          dif = variacion.prv_precio_lista - variacion.prv_precio_real
          calc = dif / variacion.prv_precio_lista
          result = calc * 100
        }
        this.setState({ result: result > 1 ? Math.round(result) : 0 })

        return result
      })
    }
  }


  render() {
    var descontinuado = false
    if (this.state.variations === undefined) {
      descontinuado = true
    }
    //console.log("DATA DE LOS PROD", this.props.dataProducto)
    return (
      <div className={descontinuado ? 'LittleProducBlock ProductDisabled' : 'LittleProducBlock'}>
        {!descontinuado && this.state.result > 1 ? <p className='ProductPill'>{this.state.result}% Off </p> : null}
        <Link to={!descontinuado ? this.getLinkToProduct() : '#'} style={{ color: 'initial', cursor: 'pointer' }}>
          <MyImage alt={this.state.producto.pro_foto} image={{ src: this.state.photo ? this.state.photo : this.state.producto.linkfoto }} />
        </Link>
        <div className='Container'>
          <div>
            <h4 onClick={!descontinuado ? this.createLinkToProduct : console.log()}>
              {this.props.dataProducto.mar_nombre}
            </h4>
            <small onClick={!descontinuado ? this.createLinkToProduct : console.log()}>
              {this.state.producto.pro_nombre}
            </small>
            <ul className='Variation'>
              {this.state.variations !== undefined ?
                this.state.variations.map((variacion, o) =>
                  <li
                    key={o}
                    onClick={() => this.selectVariation(variacion)}
                    className={this.state.variedad_select === variacion ? 'active' : ''} //State de variation selecctedd para definir active : '' 
                  >
                    {variacion.var_cantidad + " " + variacion.ume_resumido}
                  </li>
                ) :
                <li className={''}>
                  Descontinuado
                </li>

              }
            </ul>
            <Link to={!descontinuado ? this.getLinkToProduct() : '#'} style={{ color: 'initial', cursor: 'pointer' }}>
            <div className='Botonera' onClick={!descontinuado ? this.createLinkToProduct : console.log()}>
              <b className='RowCenter'>
                {this.state.variations !== undefined &&
                  this.state.variations.map((variacion, i) =>
                    variacion.prv_id === this.state.variedad_select.prv_id && variacion.prv_precio_lista !== 0 ?
                      <p key={i} className='oldPrice'>
                        <NumberMP total={variacion.prv_precio_lista} prefix={'$'} />
                      </p>
                      : null
                  )}
                {this.state.variations !== undefined &&
                  this.state.variations.map((variacion, i) =>
                    variacion.prv_id === this.state.variedad_select.prv_id &&
                    <p key={i} className='newPrice'>
                      <NumberMP total={variacion.prv_precio_real} prefix={'$'} />
                    </p>
                  )}
              </b>
              {!descontinuado &&
                <button className='btn-CompraVerde Btn-Small' >
                  {this.props.labelText ? this.props.labelText : 'Action'}
                </button>
              }

            </div>
          </Link>

          </div>
        </div>
        {!descontinuado && this.props.dia_gratis &&
          <div className='InfoEnvio' onClick={this.createLinkToProduct}>
            <Link to={!descontinuado ? this.getLinkToProduct() : '#'} style={{ color: 'initial', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <img alt='motito' src={require('../../IMG/icono-motito.png')} />
              <div>Llega gratis {this.props.dia_gratis}</div>
            </Link>
          </div>}
      </div>
    );
  }
}

class ItemCarrito extends React.Component {
  render() {
    return (
      <div className='ItemCarrito RowCenter'>
        <img alt='foto del producto' src='https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/desktop_absorsol-pack-x-4-piedritas-absorsol-bolsa-verde-x-3.6-kg_267' />
        <h3>
          Piedritas Absorsol pack x4, bolsa verde
        </h3>
        <div className=''>
          <button>
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <p>
            1
          </p>
          <button>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    );
  }
}

class VeterinaryCard extends React.Component {

  redirectGooglemaps = () => {
    return
  }

  render() {
    return (

      <article className='Card VeterinaryCard'>
        <div className='RowCenter'>
          <img
            alt="Logo de la veterinaria"
            src={require('../../IMG/estetoscopio.png')}
          />
          <div className='VeterinaryCardBody'>
            <h4>{this.props.data.title}</h4>
            <p>{this.props.data.domi}</p>
            <small>{this.props.data.horario}</small>
            {!isMobile &&
              <h5>{this.props.data.telefono}</h5>
            }
          </div>
        </div>
        {isMobile ?
          <div className='RowCenter'>
            <a className='w100 Btn-Action ColumnCenter JustifyCenter Btn-Small' href={"tel:" + this.props.data.telefono}>Llamar</a>
            <a className='w100 Btn-Action ColumnCenter JustifyCenter Btn-Small' href={this.props.data.mapa} target="_blank" rel="noopener noreferrer">Ver distancia</a>
          </div>
          :
          <div className='RowCenter'>
            <a className='w100 Btn-Action Btn-Small' href={this.props.data.mapa} target="_blank" rel="noopener noreferrer">Ver distancia</a>
          </div>
        }
      </article>
    )
  }
}


class FavoritProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: this.props.duration,
      variable: this.props.duration > 0 ? this.props.duration : '',
      showDuration: false
    }
  }


  transformStringToUriString = (value) => {
    let newValue = value
    return newValue.replace(/ /g, "-");
  }

  favoriteProduct = (prod) => {
    let catName = prod.cat_nombre ? this.transformStringToUriString(prod.cat_nombre) : null;
    let brandName = prod.pro_marca ? this.transformStringToUriString(prod.pro_marca) : null;
    let prodName = prod.pro_nombre ? this.transformStringToUriString(prod.pro_nombre) : null;
    let proId = prod.pro_id;
    let prv_id = prod.prv_id;
    this.props.history.push('/productos/' + catName + '/' + brandName + '/' + prodName + '/' + proId + '/' + prv_id);
    return this.props.history.go();
  }

  componentWillReceiveProps(next_props) {
    // Cambio de Tab
    if (next_props.duration !== this.props.duration) {
      this.setState({ duration: next_props.duration })
    }
  }



  changeDuration = () => {
    this.props.changeDuration(this.props.producto, this.state.variable)
  }


  render() {
    return (
      <div className='LittleProducBlock FavoritProduct'>
        {/** <div className='Btn-Delete'>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>*/}
        <img alt={this.props.producto.prv_image} src={this.props.producto.prv_image} />
        <div>
          <div>
            <h4>{this.props.producto.pro_marca}</h4>
            <small>
              {this.props.producto.prv_nombre_full}
            </small><br />
            {this.state.duration > 0 && <span>Te dura {this.state.duration} {this.state.duration > 1 ? "Dias" : "Dia"}</span>}
          </div>
          {!this.props.showInput ?
            <div className='w100 RowCenter'>
              <button className='w100 Btn-Action Btn-Outline' onClick={() => this.props.showDuration(this.props.producto.prv_id)}>
                {this.props.duration > 0 ? 'Cambiar Duracion' : '¿Cuánto te dura?'}
              </button>
              <button className='w100 Btn-Action' onClick={() => this.favoriteProduct(this.props.producto)}>
                {this.props.labelText ? this.props.labelText : 'Action'}
              </button>
            </div>
            :
            this.props.visible === this.props.producto.prv_id &&
            <div className='w100 RowCenter Duration'>
              <input
                placeholder='¿Cuánto te dura?'
                type='tel'
                value={this.state.variable}
                onChange={(val) => this.setState({ variable: val.target.value })}
              />
              <button className='cancel' onClick={() => this.props.showDuration()}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button className='success' onClick={() => this.changeDuration()}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          }
        </div>
      </div>
    )
  }
}


class SimilarProd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: this.props.dataProducto,
      variations: this.props.dataProducto.array_variaciones,
      variedad_select: [],
      photo: ''
    }
  }

  componentDidMount() {
    this.lastedElements();
  }

  dimensions = () => {
    var aux = '', replaced = '';
    if (isMobile && this.state.variedad_select.linkfoto !== undefined) {
      aux = this.state.variedad_select.linkfoto
      this.setState({ photo: aux })
    } else {
      aux = this.state.variedad_select.linkfoto
      replaced = aux.replace("desktop_", "mobile_")
      this.setState({ photo: replaced })
    }
  }

  lastedElements() {
    var Aux = this.state.variations
    if (this.state.variations !== undefined) {
      var last_element = Aux[Aux.length - 1];
      this.setState({ variedad_select: last_element }, () => this.dimensions())
    }
  }

  selectVariation(variacion) {
    this.setState({ variedad_select: variacion })
  }

  transformStringToUriString = (value) => {
    let newValue = value
    return newValue.replace(/ /g, "-");
  }

  createLinkToProduct = () => {
    let catName = this.props.dataProducto.cat_nombre ? this.transformStringToUriString(this.props.dataProducto.cat_nombre) : null;
    let brandName = this.props.dataProducto.mar_nombre ? this.transformStringToUriString(this.props.dataProducto.mar_nombre) : null;
    let prodName = this.props.dataProducto.pro_nombre ? this.transformStringToUriString(this.props.dataProducto.pro_nombre) : null;
    let proId = (this.props.dataProducto.pro_id);
    let prv_id = (this.state.variedad_select.prv_id);
    this.props.history.push('/productos/' + catName + '/' + brandName + '/' + prodName + '/' + proId + '/' + prv_id);
    return this.props.history.go();
  }




  render() {
    return (
      <div className='LittleProducBlock'>
        <MyImage alt={this.state.producto.pro_foto} image={{ src: this.state.photo }} />
        <div>
          <h4 onClick={this.createLinkToProduct}>
            {this.props.dataProducto.mar_nombre}
          </h4>
          <small onClick={this.createLinkToProduct}>
            {this.state.producto.pro_nombre}
          </small>
          <ul className='Variation'>
            {this.state.variations !== undefined && this.state.variations.length > 0 ?
              this.state.variations.map((variacion, o) =>
                <li
                  key={o}
                  onClick={() => this.selectVariation(variacion)}
                  className={this.state.variedad_select === variacion ? 'active' : ''} //State de variation selecctedd para definir active : '' 
                >
                  {variacion.var_cantidad + " Kgs"}
                </li>
              )
              :
              <li className={'active'}>
                Descontinuado
              </li>
            }
          </ul>
          <div className='w100 RowCenter SpaceBetween ' onClick={this.createLinkToProduct}>
            <b className='RowCenter'>
              {this.state.variations !== undefined &&
                this.state.variations.map((variacion, i) =>
                  variacion.prv_id === this.state.variedad_select.prv_id && variacion.prv_precio_lista !== 0 ?
                    <p key={i} className='oldPrice'>
                      {'$' + variacion.prv_precio_lista}
                    </p>
                    : null
                )}
              {this.state.variations !== undefined &&
                this.state.variations.map((variacion, i) =>
                  variacion.prv_id === this.state.variedad_select.prv_id ?
                    <p key={i} className='newPrice'>
                      {'$' + variacion.prv_precio_real}
                    </p>
                    :
                    null
                )}
            </b>
            <button className='btn-CompraVerde Btn-Small LimitSize' onClick={this.createLinkToProduct}>
              {this.props.labelText ? this.props.labelText : 'Action'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const SimilarProduct = withRouter(SimilarProd)
const Favorite = withRouter(FavoritProduct)
const Veterinary = withRouter(VeterinaryCard)
export default withRouter(LittleProductBlock);
export { ItemCarrito, Favorite, Veterinary, SimilarProduct };