import React, { Component } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { urlRaiz } from '../../utils/Oliverhelper';

const zoho = window.$zoho;

class BannerImages extends Component {

  chatOpen = () => {
    zoho && zoho.salesiq.chat.start()
  }
  render() {
    return (
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
        onClickItem={i => {
          if (i === 2) {
            this.props.history.push('/politicas-envios');
          } else if (i === 3) {
            this.chatOpen();
          }
        }}
      >
        {/**<img alt="3m sin interes" src={require('../../IMG/banner_5.jpg')} /> */}
        <img alt="compra segura" src={require('../../IMG/banner_3.jpg')} />
        <img alt="envío gratis" src={require('../../IMG/banner_2.jpg')} />
        <img alt="chatea con nosotros" src={require('../../IMG/banner_4.jpg')} />
      </Carousel>
    );
  }
}

class BannerImagesDesk extends Component {
  render() {
    return (
      <div className='RowCenter BannerImagesDesk'>
        {/**<img alt="3m sin interes" src={require('../../IMG/banner_5.jpg')} /> */}
        <img alt="compra segura" src={require('../../IMG/banner_3.jpg')} />
        <img alt="envío gratis" onClick={() => this.props.history.push('/politicas-envios')} src={require('../../IMG/banner_2.jpg')} />
        <img alt="chatea con nosotros" onClick={() => zoho && zoho.salesiq.chat.start()} src={require('../../IMG/banner_4.jpg')} />
      </div>
    );
  }
}

function HomeDeskBanner() {
  const history = useHistory();
  var bannersDesk = [
    { alt: 'royal_canin', source: urlRaiz + 'banner_royalcanin_01.jpg', categ: 'marca', value_categ: 'Royal-Canin' },
    { alt: 'nupec', source: urlRaiz + 'banner_nupec_01.jpg', categ: 'marca', value_categ: 'Nupec' },
    { alt: 'hills', source: urlRaiz + 'banner_proplan_01.jpg', categ: 'marca', value_categ: 'Pro-Plan' },
    { alt: 'pro_plan', source: urlRaiz + 'desktop_hills.jpg', categ: 'marca', value_categ: 'Hills' },
    { alt: 'alimentos humedos', source: urlRaiz + 'banner_institucional_01.jpeg', categ: 'condicion', value_categ: 'humedo' },
    { alt: 'medicado', source: urlRaiz + 'banner_institucional_02.jpg', categ: 'medicado', value_categ: 'si' },
  ]
  return (
    <section className='HomeDeskBanner'>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
      >
        {bannersDesk.map((ban, ind) =>
          <div key={ind} onClick={() => history.push({
            pathname: "/productos",
            search: '?' + ban.categ + '=' + ban.value_categ
          })}>
            <img alt={ban.alt} src={ban.source} />
          </div>
        )}
      </Carousel>
    </section>
  )
}

function HomeDeskBottomBanner() {
  return (
    <section className='informativos'>
      <h4>Por qué elegirnos</h4>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
      >
        <div className="GridDos">
          <img alt='informativo_desktop_01.png' src={urlRaiz + 'informativo_desktop_01.png'} />
          <img alt='informativo_desktop_02.png' src={urlRaiz + 'informativo_desktop_02.png'} />
        </div>
        <div className="GridDos">
          <img alt='informativo_desktop_03.png' src={urlRaiz + 'informativo_desktop_03.png'} />
          <img alt='informativo_desktop_04.png' src={urlRaiz + 'informativo_desktop_04.png'} />
        </div>
        <div className="GridDos">
          <img alt='informativo_desktop_05png' src={urlRaiz + 'informativo_desktop_05.png'} />
          <img alt='informativo_desktop_06png' src={urlRaiz + 'informativo_desktop_06.png'} />
        </div>
      </Carousel>
    </section>
  )
}

const BannerImagesDeskRouter = withRouter(BannerImagesDesk);
export default withRouter(BannerImages);
export { BannerImagesDeskRouter, HomeDeskBanner, HomeDeskBottomBanner }