import React, { Suspense } from 'react';
import LoginButton from '../Login/LoginButton';
import SearchBox from '../Search/SearchBox';
//import Sidebar from './Sidebar';
import { withRouter } from "react-router-dom";
//import { isMobile } from 'react-device-detect';
import { OliverLogoNew } from '../Tools/OliverIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLock, faHome } from '@fortawesome/free-solid-svg-icons';
import SelectCountry from '../Tools/SelectCountry';


const UserAddress = React.lazy(async () => await import('../Address/UserAddress'))
const Cart = React.lazy(async () => await import('./Cart'))

class HomeNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  reloadPage = () => {
    var location = window.location.pathname
    if (location !== '/' || location !== '/carrito/para-pagar/' || location !== '/card-payment') {
      this.props.history.push('/')
    }
  }

  goToLogged = () => {
    this.props.history.push('/loading')
    return this.props.history.go()
  }

  render() {
    return (
      <nav className={'HomeNavbar RowCenter'}>

        <LeftComponent
          param={this.props.case}
          goBack={this.goBack}
          reloadPage={this.reloadPage}
          showDrawer={this.props.showDrawer}
          goToLogged={this.goToLogged}
        />

        <CenterComponent param={this.props.case} reloadPage={this.reloadPage} />

        <RightComponent
          param={this.props.case}
          showAddress={this.props.showAddress}
          showDrawer={this.props.showDrawer}
          toggleDrawer={this.props.toggleDrawer}
          showCart={this.props.showCart}
          toggleModal={this.props.toggleModal}
          showLoginForm={this.props.showLoginForm}
        />

      </nav>
    )
  }
}



const LeftComponent = ({ goToLogged, param, showDrawer, goBack, reloadPage }) => {


  switch (param) {
    case 'home':
    case 'ProductList':
    case 'IndividualProduct':

      return (
        <div className='RowCenter'>

          <OliverLogoNew reloadPage={reloadPage} />

          <SearchBox showDrawer={showDrawer} />

          <SelectCountry />
        </div>
      )

    case 'PurchaseOption':
    case 'carrito':
    case 'PaymentMethod':
    case 'PreguntasFrecuentes':
    case 'Terminos':
    case 'Privacidad':
      return (
        <button className='NavbarBackIcon' onClick={() => goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      )

    case 'PurchaseMade':
      return (
        <button className='NavbarBackIcon' onClick={() => goToLogged()}>
          <FontAwesomeIcon icon={faHome} />
        </button>
      )


    default:
      return <div />
  }
}

const CenterComponent = ({ param, reloadPage }) => {

  switch (param) {
    case 'home':
      return <div />

    case 'carrito':
    case 'PurchaseMade':
      return (
        <OliverLogoNew reloadPage={reloadPage} />
      )

    case 'PaymentMethod':
    case 'PreguntasFrecuentes':
    case 'Terminos':
    case 'Privacidad':
      return (
        <OliverLogoNew reloadPage={() => console.log('Click en Logo')} />
      )


    default:
      return <div />
  }
}

const RightComponent = ({ param, showAddress, showDrawer, toggleDrawer, showCart, toggleModal, showLoginForm }) => {

  switch (param) {
    case 'home':
    case 'ProductList':
    case 'IndividualProduct':
      return (
        <div className='RowCenter'>
          <Suspense fallback={<div />}>
            <UserAddress
              showElement={showAddress}
              showDrawer={showDrawer}
              toggleDrawer={toggleDrawer}
            />
          </Suspense>
          <LoginButton toggleDrawer={toggleDrawer} toggleModal={toggleModal} showLoginForm={showLoginForm} />
          <Suspense fallback={<div />}>
            <Cart
              showElement={showCart}
              showDrawer={showDrawer}
              toggleDrawer={toggleDrawer}
            />
          </Suspense>
        </div>
      )

    case 'PurchaseMade':
    case 'carrito':
    case 'PaymentMethod':
      return (
        <button className='NavbarBackIcon'>
          <FontAwesomeIcon icon={faLock} />
        </button>
      )

    default:
      return <div style={{ width: '32px', marginRight: '8px' }} />;
  }
}


export default withRouter(HomeNavbar);