import React from 'react';

export default class Skeleton extends React.Component{
  render(){
    return(
      <div
        className={this.props.rounded?'Skeleton Rounded':'Skeleton'}
        style={{
          minWidth:'8px',
          minHeight:'8px',
          width:this.props.width,
          height:this.props.height,
        }}
      />
    )
  }
}